import { IFilterFieldListItem } from '../filter/filter-field-list';
import { ERequestFilterPredicate, IRequestFilterField } from '../filter/types';

import { EEventType, EProcessing_method, EventTypeMap, IEventDescription } from './types';

export const enumEventTypeToFilterFieldList = (prefix?: string): IFilterFieldListItem[] => {
  const keys: string[] = Object.keys(EEventType).filter(v => isNaN(Number(v)));

  return keys.map(v => ({ id: v, title: `${prefix}.${v}` }));
};

export const enumProcessingMethodsToFilterFieldList = (): IFilterFieldListItem[] => {
  const keys: string[] = Object.keys(EProcessing_method).filter(v => isNaN(Number(v)));

  return keys.map(v => ({ id: v, title: `processing_method.${v}` }));
};

export const enumEventsToFilterFieldList = (events: IEventDescription[], prefix?: string): IFilterFieldListItem[] => {
  const excludeEvents = [7]; //delete_user
  const items: IFilterFieldListItem[] = events
    .filter(ev => !excludeEvents.includes(ev.actionType))
    .map(ev => ({
      id: `${ev.actionType}`,
      title: `${prefix}.${ev.actionMessage.split(' ', 1)[0]}`,
    }));

  return items;
};

const convertActionError = (action: string | undefined) => {
  const actionError = Array.from(EventTypeMap.entries()).find(e => e[1] === action)?.[0];

  return actionError !== undefined ? actionError : -1;
};

export const addActionTypeField = <T>(
  actionTypeValues: string[],
  fieldName: T,
  predicate: ERequestFilterPredicate,
  fields: IRequestFilterField<T>[],
) => {
  if (actionTypeValues.length > 0) {
    const f: IRequestFilterField<T> = {
      fieldName,
      predicate,
      targetValues: actionTypeValues,
    };
    fields.push(f);
  }
};

export const addActionErrorField = <T>(
  actionErroreValues: string[],
  fieldName: T,
  predicate: ERequestFilterPredicate,
  fields: IRequestFilterField<T>[],
) => {
  if (actionErroreValues.length > 0) {
    const f: IRequestFilterField<T> = {
      fieldName,
      predicate,
      targetValues: actionErroreValues.map(value => convertActionError(value)),
    };
    fields.push(f);
  }
};
