import { IFilterData, IFilterField, TFilterPredicate } from './types';

const textFieldPredicates: TFilterPredicate[] = ['contains', 'equal', 'not_equal'];
const listFieldPredicates: TFilterPredicate[] = ['equal', 'not_equal'];
const numberFieldPredicatesAll: TFilterPredicate[] = ['equal', 'not_equal', 'greater_than_equal', 'less_than_equal'];
const numberFieldPredicates: TFilterPredicate[] = ['greater_than_equal', 'less_than_equal'];

export interface IFilterConfigKeys {
  viewTranslate: boolean;
  avocado_topics: boolean;
  gridProxyUrl: string;
  showSummaryAI: boolean;
  zip_Json: boolean;
}

interface IFilterDescriptor {
  filterFields: IFilterField[];
  configKeys: IFilterConfigKeys | undefined;
}
const filterDescriptor: IFilterDescriptor = {
  filterFields: [],
  configKeys: undefined,
};

export const getFilterDescriptor = (config: IFilterConfigKeys) => {
  if (!filterDescriptor.configKeys || JSON.stringify(config) !== JSON.stringify(filterDescriptor.configKeys)) {
    filterDescriptor.configKeys = { ...config };
    const { viewTranslate, avocado_topics, gridProxyUrl, showSummaryAI, zip_Json } = config;
    let coreFilterDescriptor: IFilterField[] = [
      {
        newFilterBlock: zip_Json,
        id: 'status',
        title: 'records.status',
        type: 'list',
        value: [],
        predicates: listFieldPredicates,
        predicate: 'equal',
      },
      {
        id: 'translationStatus',
        title: 'records.translate_status',
        type: 'list',
        value: [],
        predicates: listFieldPredicates,
        predicate: 'equal',
      },
      {
        id: 'owner',
        title: 'records.owner',
        type: 'list',
        value: [],
        predicates: listFieldPredicates,
        predicate: 'equal',
      },
      {
        id: 'caseid',
        title: 'records.meetingTitle',
        type: 'text',
        value: [],
        predicates: textFieldPredicates,
        predicate: 'contains',
      },
      {
        id: 'fileName',
        title: 'records.filename',
        type: 'text',
        value: [],
        predicates: textFieldPredicates,
        predicate: 'contains',
      },
      { id: 'loadFrom', title: 'records.loadFrom', type: 'datetime', value: '' },
      { id: 'loadTo', title: 'records.loadTo', type: 'datetime', value: '' },
      { id: 'createFrom', title: 'records.createFrom', type: 'datetime', value: '' },
      { id: 'createTo', title: 'records.createTo', type: 'datetime', value: '' },
      {
        id: 'languageResolved',
        title: 'records.language',
        type: 'list',
        value: [],
        predicates: listFieldPredicates,
        predicate: 'equal',
      },
      {
        id: 'textIdx',
        title: 'records.text',
        type: 'text',
        value: [],
        predicate: 'contains',
      },
      {
        id: 'translateIdx',
        title: 'records.translateField',
        type: 'text',
        value: [],
        predicate: 'contains',
      },
      {
        id: 'meetingParticipants',
        title: 'records.meetingParticipants',
        type: 'number',
        value: [],
        predicates: numberFieldPredicatesAll,
        predicate: 'equal',
        multiple: false,
      },
      {
        newFilterBlock: true,
        id: 'dictors',
        title: 'records.speakers',
        type: 'list',
        value: [],
        predicate: 'equal',
      },
      {
        id: 'speechDuration',
        title: 'dictors.speechDurationSec',
        type: 'number',
        value: [],
        predicates: numberFieldPredicates,
        predicate: 'greater_than_equal',
        multiple: false,
      },
      {
        id: 'wordsCount',
        title: 'dictors.wordsCount',
        type: 'number',
        value: [],
        predicates: numberFieldPredicates,
        predicate: 'greater_than_equal',
        multiple: false,
      },
      {
        id: 'topic',
        title: 'records.topic',
        type: 'list',
        value: [],
        predicate: 'equal',
      },
      {
        id: 'topicThreshold',
        title: 'records.topicThreshold',
        type: 'threshold',
        value: 50,
        predicate: 'equal',
      },
    ];

    const summaryFilterDescriptor: IFilterField[] = [
      {
        newFilterBlock: true,
        id: 'summaryText',
        title: 'summary.filterText',
        type: 'text',
        value: [],
        predicate: 'contains',
        predicates: ['contains', 'equal'],
        maxValues: 20,
      },
      {
        id: 'summaryInstructions',
        title: 'summary.filterInstructions',
        type: 'switch',
        value: false,
        predicate: 'equal',
        multiple: false,
      },
      {
        id: 'summaryTopics',
        title: 'summary.filterTopics',
        type: 'text',
        value: [],
        predicate: 'contains',
        predicates: ['contains'],
      },
    ];

    const jsonFilterDescriptor1: IFilterField[] = [
      {
        newFilterBlock: true,
        id: 'idMetadata',
        title: 'records.metadata_id',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
      {
        id: 'conversationIdMetadata',
        title: 'records.metadata_conversationID',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
      {
        id: 'nameMetadata',
        title: 'records.metadata_name',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
      {
        id: 'nicknameMetadata',
        title: 'records.metadata_nickname',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
    ];
    const jsonFilterDescriptor2: IFilterField[] = [
      {
        id: 'sourceMetadata',
        title: 'records.metadata_source',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
      {
        id: 'destinationMetadata',
        title: 'records.metadata_destination',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
      { id: 'startTimestampFromMetadata', title: 'records.metadata_startTimestampFrom', type: 'datetime', value: '' },
      { id: 'startTimestampToMetadata', title: 'records.metadata_startTimestampTo', type: 'datetime', value: '' },
      { id: 'endTimestampFromMetadata', title: 'records.metadata_endTimestampFrom', type: 'datetime', value: '' },
      { id: 'endTimestampToMetadata', title: 'records.metadata_endTimestampTo', type: 'datetime', value: '' },
      {
        id: 'durationMetadataFrom',
        title: 'records.metadata_durationFrom',
        type: 'number',
        value: '',
        predicates: numberFieldPredicates,
        predicate: 'greater_than_equal',
      },
      {
        id: 'durationMetadataTo',
        title: 'records.metadata_durationTo',
        type: 'number',
        value: '',
        predicates: numberFieldPredicates,
        predicate: 'greater_than_equal',
      },
      {
        id: 'compressionRateMetadata',
        title: 'records.metadata_compressionRate',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
      {
        id: 'criterionMetadata',
        title: 'records.metadata_criterion',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
      {
        id: 'typeMetadata',
        title: 'records.metadata_type',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
      {
        id: 'priorityMetadata',
        title: 'records.metadata_priority',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
      {
        id: 'locationMetadata',
        title: 'records.metadata_location',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
      {
        id: 'imeiMetadata',
        title: 'records.metadata_imei',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
      {
        id: 'contentMetadata',
        title: 'records.metadata_content',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
      {
        id: 'customMetadata',
        title: 'records.metadata_custom',
        type: 'text',
        value: [],
        predicates: ['contains'],
        predicate: 'contains',
      },
    ];

    if (!viewTranslate) {
      coreFilterDescriptor = coreFilterDescriptor.filter(f => !['translationStatus', 'translateIdx'].includes(f.id));
    }
    if (!avocado_topics) {
      coreFilterDescriptor = coreFilterDescriptor.filter(f => !['topic', 'topicThreshold'].includes(f.id));
    }

    if (!gridProxyUrl || gridProxyUrl.length <= 0) {
      coreFilterDescriptor = coreFilterDescriptor.filter(f => !['dictors'].includes(f.id));
    }

    filterDescriptor.filterFields = zip_Json ? [...jsonFilterDescriptor1] : [];
    filterDescriptor.filterFields.push({
      id: 'preprocessingType',
      title: 'records.preprocessingType',
      type: 'list',
      value: [],
      predicates: listFieldPredicates,
      predicate: 'equal',
    });

    if (zip_Json) {
      filterDescriptor.filterFields.push(...jsonFilterDescriptor2);
    }
    filterDescriptor.filterFields.push(...coreFilterDescriptor);
    if (showSummaryAI) {
      filterDescriptor.filterFields.push(...summaryFilterDescriptor);
    }
  }

  return filterDescriptor.filterFields;
};

export const getDefaultFilter = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filter: any = {};
  filterDescriptor.filterFields.forEach(fd => {
    filter[fd.id] = { value: fd.value, score: fd.score, predicate: fd.predicate };
  });

  return filter as IFilterData;
};
