import { trimStart } from 'lodash';
import { makeAutoObservable } from 'mobx';

import * as uuid from 'uuid';

import { getTitle } from '../dictors/dictor-utils';
import { IPersonCardDictor } from '../records/types';

import { gridCardToDictor, personCardsToDictors } from './person-cards-utils';
import { ICreateCardProps, IPersonCard } from './types';

import { gridProxyService } from '@/services/grid-proxy/grid-proxy-service';
import { ICreateCardRequest } from '@/services/grid-proxy/types';
import { IDictor } from '@/types/dictors';
import { EAlgorithmSnakeToPascal, EModelStateSnakeToPascal } from '@/types/segmentation-types';

import { gAPP_STORE } from '@/app/app-store';

export class PersonCardsStore {
  data: IPersonCard[] = [];
  cacheKey?: string;
  dictor?: IDictor;

  constructor() {
    makeAutoObservable(this);
  }

  setCacheKey(cacheKey?: string) {
    this.cacheKey = cacheKey;
  }

  setDictor(value?: IDictor) {
    this.dictor = value;
  }

  setData(cards: IPersonCard[]) {
    this.data = cards;
  }

  searchCards = async (maxParticipants: number, dictor?: IDictor) => {
    if (dictor) {
      this.setCacheKey(undefined);
      this.setDictor(undefined);
      this.setDictor(dictor);
    }
    const dto = dictor && this.dictorToICreateCardRequest(dictor);
    if (!dto) {
      throw new Error('Cannot create personal card. Not enough data in IDictor object.');
    }

    await gridProxyService.searchCards(maxParticipants, dictor, undefined, dto);
  };

  createCard = async (props: ICreateCardProps): Promise<void> => {
    const { dictor, accessGroups } = props;
    this.setCacheKey(undefined);
    this.setDictor(undefined);
    const dto = this.dictorToICreateCardRequest(dictor, accessGroups);
    if (!dto) {
      throw new Error('Cannot create personal card. Not enough data in IDictor object.');
    }
    this.setDictor(dictor);

    dto.VoiceModel.Quality = dictor.metadata && dictor.metadata.segmentations[0].quality;

    const key = await gridProxyService.createCard(dto);
    if (uuid.validate(key)) {
      this.setCacheKey(key);
      this.setDictor(dictor);
    } else {
      throw new Error(`Cannot create personal card. Not valid response from gridid-proxy. Value '${key}' is not uuid.`);
    }

    return;
  };

  getPersonCardDictors = (cards: IPersonCard[]): IPersonCardDictor[] => {
    const result = personCardsToDictors(cards);
    this.setData([]);

    return result;
  };

  getStoredDictorAndUpdateFromCard = (card: IPersonCard): IDictor | undefined => {
    if (!this.dictor) {
      return undefined;
    }

    const result: IDictor = gridCardToDictor(card, this.dictor);
    this.setDictor(undefined);
    this.setCacheKey(undefined);

    return result;
  };

  private dictorToICreateCardRequest = (dictor: IDictor, accessGroups?: number[]): ICreateCardRequest | null => {
    if (!dictor.metadata) {
      return null;
    }

    const audioSplitted = dictor.metadata.segmentations[0].audio?.split('/') || [];
    if (audioSplitted.length < 1) {
      return null;
    }
    const storageKye = audioSplitted[audioSplitted.length - 1];
    const segmkey = trimStart(dictor.metadata.segmentations[0].path, 'file:/');
    const dictorsStore = gAPP_STORE.getDictorsStore();
    const dto: ICreateCardRequest = {
      VoiceModel: {
        Key: `file:/storage${dictor.metadata.path}`,
        BioSdkVersion: dictor.metadata.bio_version,
        State: EModelStateSnakeToPascal[dictor.metadata.model_state],
      },
      AccessGroups: accessGroups,
      FilesWithSegmentations: [
        {
          StorageKey: storageKye,
          FileName: dictor.metadata.segmentations[0].fileName,
          FileSize: dictor.metadata.segmentations[0].fileSize,
          FileHash: dictor.metadata.segmentations[0].audio_hash,
          SegmentationKey: `/${segmkey}`,
          ChannelNumber: dictor.metadata.segmentations[0].channel_number,
          ChannelType: dictor.metadata.segmentations[0].channel_type,
          AlgorithmType: EAlgorithmSnakeToPascal[dictor.metadata.segmentations[0].algorithm],
          BioSdkVersion: dictor.metadata.segmentations[0].bio_version,
          Quality: dictor.metadata.segmentations[0].quality,
          TimeSpeech: dictor.metadata.segmentations[0].time_speech,
          SnrIntegralDb: dictor.metadata.segmentations[0].snr,
          RtAver: dictor.metadata.segmentations[0].rt_aver,
          DictorNumber: dictor.metadata.segmentations[0].dictor_num,
          LowestSpeechFreqHz: dictor.metadata.segmentations[0].lowest_speech_freq_hz,
          HighestSpeechFreqHz: dictor.metadata.segmentations[0].highest_speech_freq_hz,
          SpeechAmplitudeThresholdDb: dictor.metadata.segmentations[0].speech_amplitude_threshold_db,
          RtRms: dictor.metadata.segmentations[0].rt_rms,
          RtCount: dictor.metadata.segmentations[0].rt_count,
          TimeTonalNoise: dictor.metadata.segmentations[0].time_tonal_noise,
          TimeOverload: dictor.metadata.segmentations[0].time_overload,
          MaxUnclippedInterval: dictor.metadata.segmentations[0].max_unclipped_interval,
          MinDictorsCount: 1,
          MaxDictorsCount: 5,
        },
      ],
      Gender: dictor.gender,
      Name: getTitle(dictor),
    };

    return dto;
  };
}
