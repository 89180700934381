import i18n from 'i18next';

import { toast } from 'react-toastify';

import { BackendService } from '../backend-service';
import { redisService } from '../redis-service';

import { userService } from './../user/user-service';

import { ICreateCardRequest } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { getTitle } from '@/components/dictors/dictor-utils';
import { EventLogService, TInformDictorCard } from '@/components/eventLog/event-log-service';
import { IGridAnswer, IUploadResult2 } from '@/components/records/types';
import { IDictor } from '@/types/dictors';

class GridProxyService {
  private async goOut(loginUrl: string, body: string) {
    const token = await userService.generateJWTToken();
    const options = {
      noPrefix: true,
      sessionId: `Bearer ${token}`,
    };

    const data = await BackendService.post(loginUrl, body, options);

    if (data.length) {
      window.location.href = data;
    }

    return data;
  }

  async searchHotlists(maxItems: number, dialogStore?: IUploadResult2) {
    const currentUrl = window.location.href;
    const loginUrl = 'gridid/gsr-integration/api/v2/mediator/cache/get-hot-lists';
    const internalStateRedisId = await redisService.putString(gAPP_STORE.getInternalState(false, dialogStore));
    const body = {
      ExtraMetadata: internalStateRedisId,
      RedirectTo: `${currentUrl?.split('?')[0]}?cacheKey={0}`,
      SearchInfo: {
        MaxHotlistCount: maxItems,
      },
    };

    try {
      return await this.goOut(loginUrl, JSON.stringify(body));
    } catch (e) {
      toast.error(`${i18n.t('ActionStatusError')}`);
    }
  }

  async searchCards(maxParticipants: number, dictor?: IDictor, dialogStore?: IUploadResult2, dto?: ICreateCardRequest) {
    const currentUrl = window.location.href;
    const loginUrl = 'gridid/gsr-integration/api/v2/mediator/cache/search-cards-by-voice';
    const SourceSpeakerName = !dictor ? undefined : getTitle(dictor);

    const internalStateRedisId = await redisService.putString(gAPP_STORE.getInternalState(false, dialogStore));

    const createBody = {
      ExtraMetadata: internalStateRedisId,
      RedirectTo: `${currentUrl?.split('?')[0]}?cacheKey={0}`,
      SearchInfo: {
        ...dto,
        SourceSpeakerName,
        Threshold: gAPP_STORE.threshold,
        MaxSpeakersCount: maxParticipants,
      },
    };
    try {
      return await this.goOut(loginUrl, JSON.stringify(createBody));
    } catch (e) {
      EventLogService.informDictorCard(dictor, TInformDictorCard.errorSearch);
      toast.error(`${i18n.t('ActionStatusError')}`);
    }
  }

  async createCard(dto: ICreateCardRequest) {
    const currentUrl = window.location.href;
    const loginUrl = 'gridid/gsr-integration/api/v2/mediator/cache/create-card-by-voice';

    gAPP_STORE.getCurrentCaseid(gAPP_STORE.getPersonCardsStore().dictor?.recordId.toString());

    const internalStateRedisId = await redisService.putString(gAPP_STORE.getInternalState(true));

    const createBody = {
      ExtraMetadata: internalStateRedisId,
      RedirectTo: `${currentUrl?.split('?')[0]}?cacheKey={0}`,
      CardByVoiceInfo: {
        ...dto,
        Description: gAPP_STORE.currentCaseId,
      },
    };

    try {
      return await this.goOut(loginUrl, JSON.stringify(createBody));
    } catch (e) {
      EventLogService.informDictorCard(gAPP_STORE.getPersonCardsStore().dictor, TInformDictorCard.errorCreate);
      toast.error(`${i18n.t('ActionStatusError')}`);
    }
  }

  async getGidAnswer(cacheKey: string): Promise<IGridAnswer | undefined> {
    const loginUrl = `gridid/gsr-integration/api/v2/mediator/cache/gid-answer/${cacheKey}`;
    try {
      const params = {
        noPrefix: true,
      };
      const result: IGridAnswer = await BackendService.get(loginUrl, params);

      return result;
    } catch (e) {
      console.error(e);

      return;
    }
  }

  async searchInArchive() {
    const url = 'gridid/gsr-integration/api/v2/mediator/cache/to-asq';

    try {
      const token = await userService.generateJWTToken();
      const params = {
        noAuth: true,
        noPrefix: true,
        sessionId: `Bearer ${token}`,
      };

      const data = await BackendService.get(url, params);

      if (data.length) {
        window.location.href = data;
      }

      return data;
    } catch (e) {
      toast.error(`${i18n.t('ActionStatusError')}`);
    }
  }
}

export const gridProxyService = new GridProxyService();
