import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SlUser, SlUserFemale } from 'react-icons/sl';
import { observer } from 'mobx-react';
import moment from 'moment';
import 'moment-duration-format';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';

import { CachedAvatar, ImageCacheType } from '../avatar/cached-avatar';
import { DictorAvatar } from '../avatar/dictor-avatar';

import { IDictor } from '@/types/dictors';
import { gridStorageService } from '@/services/grid-storage/grid-storage-service';
import { MAX_DICTOR_NAME_LENGTH_POPPER } from '@/common/constants';
import { getShortTitleFromDictor } from '@/components/dictors/dictor-utils';

interface IDictorPanelProps {
  dictor: IDictor;
  cache?: ImageCacheType;
}

const DictorPanel: FC<IDictorPanelProps> = observer(props => {
  const { dictor, cache } = props;

  const { t } = useTranslation();

  const dictorName = useMemo(() => getShortTitleFromDictor(dictor, MAX_DICTOR_NAME_LENGTH_POPPER), [dictor]);

  const avatarUrl = useMemo(
    () => (dictor.avatar ? gridStorageService.getNginxUrl(dictor.recordId, dictor.avatar) : ''),
    [dictor.avatar, dictor.recordId],
  );
  const genderIcon = dictor.gender === 'female' ? <SlUserFemale size={'65%'} /> : <SlUser size={'65%'} />;

  return (
    <Box p={3} display="flex">
      <Box height={150}>
        {cache ? (
          <CachedAvatar
            isDefaultName={dictor.defaultName}
            imageCache={cache}
            alt={dictorName}
            src={avatarUrl}
            style={{ width: 150, height: 150 }}
          >
            {dictor.gender !== 'undefined' ? genderIcon : undefined}
          </CachedAvatar>
        ) : (
          <DictorAvatar
            isDefaultName={dictor.defaultName}
            alt={dictorName}
            src={avatarUrl}
            style={{ width: 150, height: 150 }}
          >
            {dictor.gender !== 'undefined' ? genderIcon : undefined}
          </DictorAvatar>
        )}
      </Box>

      <Box p={1}></Box>
      <Box>
        <Typography variant="h5" style={{ maxWidth: '330px', textOverflow: 'wrap', wordWrap: 'break-word' }}>
          {dictorName}
        </Typography>
        <Box p={1}></Box>

        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography color="textSecondary" noWrap>
                  {t('dictors.wordsCount')}
                </Typography>
              </TableCell>
              <TableCell align="right">{dictor.wordsCount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography color="textSecondary" noWrap>
                  {t('dictors.speechDuration')}
                </Typography>
              </TableCell>
              <TableCell align="right">
                {moment.duration(dictor.speechDuration, 'seconds').format('hh:mm:ss', { trim: false })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
});

export default DictorPanel;
