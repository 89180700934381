import React, { useMemo, useState } from 'react';

import { ClickAwayListener, Tooltip, TooltipProps } from '@material-ui/core';

import { IconRole } from '@/components/summary/AIicons';
import { IParticipant } from '@/components/summary/types';

type TRole = {
  participant: IParticipant;
  onRoleChanged: (value: string) => void;
  maxLength?: number;
  editable?: boolean;
  autotest: string;
};

const TooltipWrapper = (props: TooltipProps) => {
  const { title } = props;

  return title === '' ? (
    <>{props.children}</>
  ) : (
    <Tooltip title={title} style={{ backgroundColor: 'white' }}>
      {props.children}
    </Tooltip>
  );
};

export const Role = ({ participant, onRoleChanged, maxLength, editable: editableRole, autotest }: TRole) => {
  const [value, setValue] = useState(participant.role || '');
  const [editMode, setEditMode] = useState(false);

  const clickAwayHandler = () => {
    if (!editMode) return;

    onRoleChanged(value);
    setEditMode(false);
  };

  const editable = useMemo(() => editableRole && editMode, [editMode, editableRole]);

  const doubleClickHandle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.detail > 1) {
      e.preventDefault();
    }
    setEditMode(true);
  };

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <div
        data-autotest={`${autotest}-Role`}
        style={{
          display: 'flex',
          height: '30px',
          backgroundColor: editable ? '#fff' : 'rgba(247, 249, 249, 1)',
          borderRadius: '4px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: editable ? 'rgba(11, 43, 72, 1)' : 'rgba(221, 221, 221, 1)',
          boxShadow: editable ? '0 1px 8px 0 rgba(0, 0, 0, 0.24)' : 'none',
          cursor: editableRole ? 'pointer' : 'default',
          width: 'fit-content',
          minWidth: '48px',
          padding: '0 8px 0 4px',
        }}
        onDoubleClick={doubleClickHandle}
      >
        <div style={{ width: '18px', height: '100%', display: 'flex', alignItems: 'center' }}>
          <IconRole color={editable ? '#999999' : '#111927'} />
        </div>
        <TooltipWrapper title={editMode ? '' : value}>
          <div
            style={{
              cursor: editable ? 'text' : undefined,
              position: 'relative',
              boxSizing: 'border-box',
              lineHeight: '1em',
              fontSize: '13px',
              fontFamily: 'Inter, sans-serif',
              display: 'flex',
              alignItems: 'center',
              paddingRight: '4px',
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              backgroundColor: 'transparent',
              color: editable ? 'transparent' : undefined,
            }}
          >
            {editable && (
              <input
                style={{
                  position: 'absolute',
                  fontSize: 'inherit',
                  fontFamily: 'inherit',
                  lineHeight: 'inherit',
                  width: '100%',
                  left: '-2px',
                  top: '6px',
                  boxSizing: 'border-box',
                  backgroundColor: 'transparent',
                  border: 'none',
                  outline: 'none',
                }}
                autoFocus
                disabled={!editMode}
                maxLength={maxLength}
                type="text"
                value={value}
                onChange={e => setValue(e.target.value)}
              />
            )}
            {value.length < 21 ? value : `${value.slice(0, 20)}\u2026`}
          </div>
        </TooltipWrapper>
      </div>
    </ClickAwayListener>
  );
};
