import { BackendService } from '@/services/backend-service';
import { IRecord } from '@/components/records';
import { IRequestFilterData } from '@/components/filter/types';

// const mockMeta = {
//   idMetadata: '1234567890',
//   nameMetadata:
//     'Имя звонившего в сто двадцать восемь символовов, это очень-очень длинное имя! [ 1234567890 ], А пок|=азываем только сто знаков',
//   nicknameMetadata: 'ASD1',
//   conversationIDMetadata: '123456789012345',
//   sourceMetadata: '966511111111, 966549988222',
//   destinationMetadata: '966522222222, 966549988777',
//   starttimestampMetadata: '2023-02-11T12:21:30',
//   endtimestampMetadata: '2023-02-11T12:23:06',
//   durationMetadata: '96',
//   compressionrateMetadata: 'MEDIUM',
//   criterionMetadata: '966511111111',
//   typeMetadata: '~~~X~~~',
//   priorityMetadata: 'MEDIUM',
//   locationMetadata: '42.123456789012345, 18.123456789012345',
//   imeiMetadata: '123456789012345',
//   contentMetadata: '1234567890123451234567890123456789.mp3',
//   customMetadata:
// eslint-disable-next-line max-len
//     '256 символов Сегодня погода переменчива, но это не влияет на планы. В городе спокойно, люди занимаю|=тся своими делами. Новости сообщают о событиях в мире, но важно оставаться в курсе. Время идет, и каждый день приносит что-то новое. 12345678910ФИСВУАПРШQ',
// };

class RecordService {
  getRecordsByFilter = async (filter: IRequestFilterData<keyof IRecord>): Promise<IRecord[]> => {
    try {
      const ans = await BackendService.post('file/filter', JSON.stringify(filter));

      //const result = ans.map((v: Partial<IRecord>) => ({ ...v, ...mockMeta }));

      return ans;
    } catch (error) {
      console.error('get records by filter: ', { filter, error });

      return [];
    }
  };

  getRecordsById = async (ids: number[]): Promise<IRecord[]> => {
    try {
      const ans = await BackendService.post('file/list', JSON.stringify(ids));

      return ans;
    } catch (error) {
      console.error('get records by filter: ', { ids, error });

      return [];
    }
  };

  deleteRecord = async (recordId: number): Promise<void> => {
    await BackendService.delete(`file/${recordId}`);
  };
}

export const recordService = new RecordService();
