import i18next from 'i18next';
import { action, computed, makeAutoObservable, observable } from 'mobx';

import { AutoTranslateLanguagesStore } from 'components/auto-translate/auto-translate.store';
import { DictorsStore } from 'components/dictors';
import { FilterStore } from 'components/filter/filter-store';
import { GroupsStore } from 'components/groups/groups-store';
import { LanguagesStore } from 'components/languages/languages-store';
import { LoginStore } from 'components/login/login-store';
import { PersonCardsStore } from 'components/person-cards/person-cards-store';
import { RecordSoundStore } from 'components/records/record-sound-store';
import { RecordTextStore } from 'components/records/record-text-store';
import { RecordVideoStore } from 'components/records/record-video-store';
import { RecordsStore } from 'components/records/records-store';
import { TemplatesStore } from 'components/templates/templates-store';
import { UsersStore } from 'components/users/users-store';
import { WordDictionaryStore } from 'components/wordDictionary/wordDictionary.store';
import { BackendService } from 'services';
import { CTranslationStore, TLangCode } from 'stores/translationStore';

import { ILangISO, ILoadStateResult, IViewSettings } from './types';

import {
  DEFAULT_AVAILABLE_LOCALES,
  DEFAULT_MAX_FILES_TO_UPLOAD,
  DEFAULT_MAX_HOTLIST_COUNT,
  DEFAULT_MAX_SPEAKER_COUNT,
  DEFAULT_RECORD_EXTENSIONS,
  DEFAULT_SEGMANTATION_ALGORITHM,
  DEFAULT_TEMPLATE_MAX_NAME,
  DEFAULT_TEMPLATE_MAX_SIZE,
} from '@/common/constants';
import { DataSourcesStore } from '@/components/dataSources/data-sources.store';
import { EventLogService, TInformDictorCard } from '@/components/eventLog/event-log-service';
import { EventLogStore } from '@/components/eventLog/eventLog.store';
import { NotificationsStore } from '@/components/notifications/notifications-store';
import { gridCardToDictor, gridCardToPersonCardDictor } from '@/components/person-cards/person-cards-utils';
import { IPersonCard } from '@/components/person-cards/types';
import { ICachedData, IGridAnswer, IHotlist, IPersonCardDictor, IUploadResult2 } from '@/components/records/types';
import { SummaryStore } from '@/components/summary/summary-store';
import { IGridInscriptions } from '@/react-ui-kit/src';
import { EntityChangeEventsService } from '@/services/events/entity-change-events-service';
import { redisService } from '@/services/redis-service';
import { settingsService } from '@/services/settings';
import { Language } from '@/types/common';
import { IDictor } from '@/types/dictors';
import { checkCasheKeyUrl, checkLangKeyUrl } from '@/utils/checkUrl';

class CAppStore {
  punctuationModelKey = 'transcriber';
  showDoc = false;
  recordExtensionsForLoading: string[] = [DEFAULT_RECORD_EXTENSIONS];
  APP_NAME = '';
  APP_VERSION?: string;
  onlyAdminCanDeleteRecords = false;
  editingASR = false;
  editingTranslate = false;
  viewTranslate = false;
  punctuation = false;
  groups = false;
  avocado_wordsDictionary = false;
  avocado_topics = false;
  showBarRecordInfo = false;
  maxFileSize = 0;
  maxFilesToUpload = 0;
  maxSpeakerCount = 0;
  maxHotlistCount = 0;
  multiLanguageRecognition = false;
  autoLanguageDetection = false;
  autoSpeakerDetection = false;
  threshold = 0;
  gridProxyUrl = '';
  gridProxyAuthToken = '';
  punctuationModelLanguages: Language[] = [];
  showDictorInfoCard = false;
  showLabelsColumn = false;
  showChangeLabelsButton = false;
  availableLocales: TLangCode[] = [];
  availableAlgorithms: string[] = [];
  meetingTitleColumnName: string | boolean | undefined;
  showSumMeeting = false;
  showSumBriefRoles = false;
  showSumBriefTopics = false;
  showSumDecisions = false;
  showKeyDecisions = false;
  showAmbiguities = false;
  showTimecodes = false;
  showSumInstructions = false;
  showSummaryAI = false;
  maxCiscoChannels = 1000;
  summarizationPauseLength = 1;
  templateMaxFileName = 0;
  templateMaxFileSize = 0;
  zip_Json = false;
  zip_ZOOM = false;
  zip_Simple = false;

  statusCodeError = 0;
  currentCaseId = '';
  // returnItems from grid-id
  dataGridAnswer?: IGridAnswer;

  @observable
  languages: ILangISO[] = [];
  @observable
  isLanguagesLoading = false;
  tableInscriptions?: IGridInscriptions;

  private _filterStore?: FilterStore;
  private _wordDictionaryStore?: WordDictionaryStore;
  private _groupsStore?: GroupsStore;
  private _dataSourcesStore?: DataSourcesStore;
  private _languagesStore?: LanguagesStore;
  private _recordsStore?: RecordsStore;
  private _templatesStore?: TemplatesStore;
  private _usersStore?: UsersStore;
  private _autoTranslateLanguagesStore?: AutoTranslateLanguagesStore;
  private _eventLogStore?: EventLogStore;
  private _recordTextStore?: RecordTextStore;
  private _dictorsStore?: DictorsStore;
  private _personCardsStore?: PersonCardsStore;
  private _notificationsStore?: NotificationsStore;
  private _translationStore?: CTranslationStore;
  private _loginStore?: LoginStore;
  private _summaryStore?: SummaryStore;

  private _soundStore: RecordSoundStore;
  private _videoStore: RecordVideoStore;
  private _entityChangeEvents: EntityChangeEventsService;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    this._soundStore = new RecordSoundStore();
    this._videoStore = new RecordVideoStore(this._soundStore);
    this._entityChangeEvents = new EntityChangeEventsService();
    this.setShowDoc(false);
  }

  sessionOff() {
    const eventService = this.entityChangeEventService;
    if (eventService) {
      eventService.forceSignOut();
    }

    this.loginStore.signOut();
    this.videoStore.reset();
    this.getRecordTextStore().clear();
  }

  async initViewSettings(): Promise<boolean> {
    try {
      const viewResponse = gAPP_STORE.loginStore.isSignedIn()
        ? await BackendService.get('version-info/protected/load', { noCache: true })
        : await BackendService.get('version-info/public/load', { noCache: true });
      const viewSettings: IViewSettings = await viewResponse;
      this.setInitViewSettings(viewSettings);
      await this.translationStore.init();
      this.updateTableLabels();
      if (gAPP_STORE.loginStore.isSignedIn()) {
        this.getRecordsStore().updateCols();
      }

      return true;
    } catch {
      console.error('Failed to fetch view_config.json!!!');
    }

    return false;
  }

  @action
  private setInitViewSettings(viewSettings: IViewSettings) {
    function inputToStringArray<T>(inValue: string | T[] | undefined, defValue: string): T[] {
      if (Array.isArray(inValue) && inValue.length > 0) return inValue;
      if (typeof inValue === 'string') return [inValue as T];

      return [defValue as T];
    }

    this.zip_Json = viewSettings.zip_Json ? true : false;
    this.zip_ZOOM = viewSettings.zip_ZOOM ? true : false;
    this.zip_Simple = viewSettings.zip_Simple ? true : false;

    this.APP_NAME = viewSettings.APP_NAME ?? '';
    this.APP_VERSION = viewSettings.APP_VERSION || '';
    this.onlyAdminCanDeleteRecords = viewSettings.onlyAdminCanDeleteRecords || false;
    this.editingASR = viewSettings.editingASR || false;
    this.editingTranslate = viewSettings.editingTranslate || false;
    this.viewTranslate = viewSettings.viewTranslate || false;
    this.groups = viewSettings.groups || false;
    this.punctuation = viewSettings.punctuation || false;
    this.avocado_wordsDictionary = viewSettings.avocado_wordsDictionary || false;
    this.avocado_topics = viewSettings.avocado_topics || false;
    this.showBarRecordInfo = viewSettings.showBarRecordInfo ?? false;
    this.maxCiscoChannels = viewSettings.maxCiscoChannels ?? 1000;
    this.maxFileSize = viewSettings.maxFileSize || 0;
    this.maxFilesToUpload = viewSettings.maxFilesToUpload || DEFAULT_MAX_FILES_TO_UPLOAD;
    this.maxSpeakerCount = viewSettings.maxSpeakerCount || DEFAULT_MAX_SPEAKER_COUNT;
    this.maxHotlistCount = viewSettings.maxHotlistCount || DEFAULT_MAX_HOTLIST_COUNT;
    this.multiLanguageRecognition = viewSettings.multiLanguageRecognition || false;
    this.autoLanguageDetection = viewSettings.autoLanguageDetection || false;
    this.autoSpeakerDetection = viewSettings.autoSpeakerDetection || false;
    this.threshold = viewSettings.threshold || 0;
    this.gridProxyUrl = viewSettings.gridProxyUrl;
    this.gridProxyAuthToken = viewSettings.gridProxyAuthToken;
    this.showDictorInfoCard = viewSettings.showDictorInfoCard || false;
    this.showSummaryAI = viewSettings.showSummaryAI ?? false;
    this.showSumMeeting = viewSettings.showSumMeeting ?? false;
    this.showSumBriefRoles = viewSettings.showSumBriefRoles ?? false;
    this.showSumBriefTopics = viewSettings.showSumBriefTopics ?? false;
    this.showSumDecisions = viewSettings.showSumDecisions ?? false;
    this.showKeyDecisions = viewSettings.showKeyDecisions ?? false;
    this.showAmbiguities = viewSettings.showAmbiguities ?? false;
    this.showTimecodes = viewSettings.showTimecodes ?? false;
    this.showSumInstructions = viewSettings.showSumInstructions ?? false;
    this.summarizationPauseLength = viewSettings.summarizationPauseLength ?? 1;
    this.templateMaxFileName = viewSettings.templateMaxFileName || DEFAULT_TEMPLATE_MAX_NAME;
    this.templateMaxFileSize = viewSettings.templateMaxFileSize || DEFAULT_TEMPLATE_MAX_SIZE;

    this.showLabelsColumn = viewSettings.showLabelsColumn || false;
    this.showChangeLabelsButton = viewSettings.showChangeLabelsButton || false;
    this.availableLocales = inputToStringArray<Language>(viewSettings.availableLocales, DEFAULT_AVAILABLE_LOCALES);
    this.availableAlgorithms = inputToStringArray<string>(
      viewSettings.availableAlgorithms,
      DEFAULT_SEGMANTATION_ALGORITHM,
    );
    if (
      (typeof viewSettings.meetingTitleColumnName === 'string' &&
        (viewSettings.meetingTitleColumnName as string).length > 0) ||
      typeof viewSettings.meetingTitleColumnName === 'number'
    ) {
      this.meetingTitleColumnName = viewSettings.meetingTitleColumnName;
    } else this.meetingTitleColumnName = undefined;
    if (viewSettings.recordExtensionsForLoading && Array.isArray(viewSettings.recordExtensionsForLoading)) {
      this.recordExtensionsForLoading = viewSettings.recordExtensionsForLoading.map(val => {
        const ext = `${val}`.trim();

        return ext.length > 0 && ext[0] !== '.' ? `.${val}` : val;
      });
    }
  }

  getInternalState(isCreateDictorCard: boolean, dialogStore?: IUploadResult2): string {
    gAPP_STORE.summaryStore.setToGrid();
    const dictorsStore = gAPP_STORE.getDictorsStore();
    const { dictors, isDictorsPanelVisible, isDictorsStatisticsDialogOpen, isSettingsPanelOpen } = dictorsStore;
    const mainData = {
      isCreateDictorCard,
      filterStore: gAPP_STORE.getFilterStore(),
      recordsStore: gAPP_STORE.getRecordsStore(),
      summaryStore: gAPP_STORE.summaryStore,
      dictor: gAPP_STORE.getPersonCardsStore().dictor,
      dictors,
      isDictorsPanelVisible,
      isDictorsStatisticsDialogOpen,
      isSettingsPanelOpen,
    };

    return JSON.stringify(
      dialogStore
        ? {
            ...mainData,
            dialogStore,
          }
        : mainData,
    );
  }

  get entityChangeEventService() {
    return this._entityChangeEvents;
  }

  get translationStore(): CTranslationStore {
    if (!this._translationStore) this._translationStore = new CTranslationStore(checkLangKeyUrl());

    return this._translationStore;
  }

  get loginStore(): LoginStore {
    if (!this._loginStore) this._loginStore = new LoginStore(checkCasheKeyUrl());

    return this._loginStore;
  }

  get summaryStore(): SummaryStore {
    if (!this._summaryStore) this._summaryStore = new SummaryStore();

    return this._summaryStore;
  }

  get soundStore(): RecordSoundStore {
    return this._soundStore;
  }

  get videoStore(): RecordVideoStore {
    return this._videoStore;
  }

  getAutoTranslateLanguagesStore(): AutoTranslateLanguagesStore {
    if (!this._autoTranslateLanguagesStore) {
      this._autoTranslateLanguagesStore = new AutoTranslateLanguagesStore();
    }

    return this._autoTranslateLanguagesStore;
  }

  getDictorsStore(): DictorsStore {
    if (!this._dictorsStore) {
      this._dictorsStore = new DictorsStore(this.summaryStore);
      if (!this.dataGridAnswer) {
        this._dictorsStore.reloadStore();
      }
    }

    return this._dictorsStore;
  }

  getEventLogStore(): EventLogStore {
    if (!this._eventLogStore) {
      this._eventLogStore = new EventLogStore();
    }

    return this._eventLogStore;
  }

  getRecordTextStore(): RecordTextStore {
    if (!this._recordTextStore) {
      this._recordTextStore = new RecordTextStore();
    }

    return this._recordTextStore;
  }

  getPersonCardsStore(): PersonCardsStore {
    if (!this._personCardsStore) {
      this._personCardsStore = new PersonCardsStore();
    }

    return this._personCardsStore;
  }

  getGroupsStore(): GroupsStore {
    if (!this._groupsStore) {
      this._groupsStore = new GroupsStore();
    }

    return this._groupsStore;
  }

  getDataSourcesStore(): DataSourcesStore {
    if (!this._dataSourcesStore) {
      this._dataSourcesStore = new DataSourcesStore();
    }

    return this._dataSourcesStore;
  }

  getUsersStore(): UsersStore {
    if (!this._usersStore) {
      this._usersStore = new UsersStore();
    }

    return this._usersStore;
  }

  getRecordsStore(): RecordsStore {
    if (!this._recordsStore) {
      this._recordsStore = new RecordsStore();
    }

    return this._recordsStore;
  }

  getCurrentCaseid(recordId: string | undefined) {
    if (this.getRecordsStore().data.records.length) {
      this.getRecordsStore().data.records.forEach(item => {
        if (item.id.toString() === recordId) {
          return (this.currentCaseId = item.caseid);
        }
      });
    } else {
      this.getRecordsStore().recordsCurrent.forEach(item => {
        if (item.id.toString() === recordId) {
          return (this.currentCaseId = item.caseid);
        }
      });
    }

    return this.currentCaseId;
  }

  setStatusCodeError(code: number) {
    this.statusCodeError = code;
  }

  loadDataFromLocalStorage(recordsStore: FilterStore) {
    this._filterStore?.setFilterData(recordsStore.data.currentFilterData);
    this._filterStore?.setTopicList(recordsStore.data.topicList);
  }

  loadSummaryStore(store: SummaryStore) {
    this.summaryStore.restore(store);
  }

  loadDataDictors(dictors: IDictor[]) {
    this._dictorsStore?.setDictors(dictors);
  }

  loadDataChecboxRowLocalStorage(recordsStore: RecordsStore) {
    this._recordsStore?.setCheckedRows(recordsStore.gridStore.checkedRows);
    setTimeout(() => {
      this._recordsStore?.gridStore.setSelectedRowIndexes(recordsStore.gridStore.selectedRowIndexes);
    });
  }

  loadDataGridStoreLocalStorage(recordsStore: RecordsStore) {
    this._recordsStore?.setGridStore(
      recordsStore.gridStore,
      recordsStore.gridStore.hasNextPage,
      recordsStore.gridStore.currentPage,
      recordsStore.gridStore.rowsOnPage,
    );
  }

  removeStorage(data: string) {
    if (localStorage.getItem(data)) {
      localStorage.removeItem(data);
    }
  }

  getStorage(data: string) {
    const recordsStoreData = localStorage.getItem(data);
    const dataStorage = recordsStoreData ? JSON.parse(recordsStoreData) : null;

    return dataStorage;
  }

  getGroups(groups: boolean) {
    this.groups = groups;
  }

  getGroupsStorageStore(groupsStore: GroupsStore) {
    this._groupsStore = groupsStore;
  }

  async loadState(): Promise<ILoadStateResult | undefined> {
    if (!this.dataGridAnswer || !this.dataGridAnswer.ExtraMetadata) return;
    const answ = this.dataGridAnswer;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: ICachedData = (await redisService.getString(this.dataGridAnswer.ExtraMetadata ?? '')) as any;
    const logCreateDictorCard = data.isCreateDictorCard;

    this.loadDataFromLocalStorage(data.filterStore);
    this.loadDataGridStoreLocalStorage(data.recordsStore);
    this.loadDataChecboxRowLocalStorage(data.recordsStore);
    this.getRecordsStore().getAllRecords(data.recordsStore.gridStore.data);
    if (data.dialogStore) {
      const groupsStore = gAPP_STORE.getGroupsStore();
      await groupsStore.getAll();
      const groupIds = new Set(groupsStore.data.groups.map(g => g.id));

      data.dialogStore.newGroups &&
        (data.dialogStore.newGroups = data.dialogStore.newGroups.filter(g => g && groupIds.has(g.id)));
      await Promise.allSettled([
        //ждем прогрузки групп текущего пользователя
        gAPP_STORE.loginStore.updateGroups(),
        //ждём прогрузки списка языков
        await this.getWordDictionaryStore().getAll(),
      ]);
      const dlgValues = data.dialogStore;
      this.dataGridAnswer = undefined; // данные считаны
      try {
        if (data.dictors) this.loadDataDictors(data.dictors);
        if (data.dictor) this.getPersonCardsStore().setDictor(data.dictor);
      } catch (err) {
        console.log(err);
      }
      if (answ.HotLists && answ.HotLists.length > 0) {
        if (!dlgValues.hotlists || dlgValues.hotlists.length === 0) {
          dlgValues.hotlists = [...answ.HotLists];
        } else {
          answ.HotLists.forEach(newHotList => {
            if (!dlgValues.hotlists?.find(hl => hl.GroupId === newHotList.GroupId)) {
              const hotList: IHotlist = { ...newHotList };
              dlgValues.hotlists?.push(hotList);
            }
          });
        }
      } else {
        if (answ.Cards) {
          const cards: IPersonCard[] = answ.Cards;
          if (cards.length > 0) {
            if (!dlgValues.participants) dlgValues.participants = [];
            cards.forEach(card => {
              if (
                dlgValues.participants &&
                dlgValues.participants.findIndex((p: IPersonCardDictor) => p.extId === card.ObjectId) < 0
              ) {
                dlgValues.participants?.push(gridCardToPersonCardDictor(card));
              }
            });
          }
        }
      }
      this.loadSummaryStore(data.summaryStore);
    }
    const card = answ.Cards && answ.Cards.length > 0 ? answ.Cards[0] : undefined;

    if (card) {
      const dictor = gridCardToDictor(card, data.dictor);

      this.getPersonCardsStore().setDictor(dictor);
      await EventLogService.informDictorCard(
        dictor,
        logCreateDictorCard ? TInformDictorCard.create : TInformDictorCard.search,
      );
    } else {
      this.loadDataDictors(data.dictors);
      //this.getPersonCardsStore().setDictor(data.dictor);
    }
    this.loadSummaryStore(data.summaryStore);

    this.dataGridAnswer = undefined;

    return { dialogStore: data.dialogStore, cachedData: data }; // уходили в grid-id из диалога загрузки записей
  }

  getNotificationsStore(): NotificationsStore {
    if (!this._notificationsStore) {
      this._notificationsStore = new NotificationsStore();
    }

    return this._notificationsStore;
  }

  clearRecordsStore() {
    if (this._recordsStore) {
      this._recordsStore.clear();
    }
  }

  getLanguagesStore() {
    if (!this._languagesStore) {
      this._languagesStore = new LanguagesStore();
    }

    return this._languagesStore;
  }

  getTemplateStore() {
    if (!this._templatesStore) {
      this._templatesStore = new TemplatesStore();
    }

    return this._templatesStore;
  }

  getFilterStore() {
    if (!this._filterStore) {
      this._filterStore = new FilterStore();
    }

    return this._filterStore;
  }

  getWordDictionaryStore() {
    if (!this._wordDictionaryStore) {
      this._wordDictionaryStore = new WordDictionaryStore();
    }

    return this._wordDictionaryStore;
  }

  async loadLanguages() {
    this.setLanguagesLoading(true);
    let data: ILangISO[] = [];
    try {
      data = await BackendService.get('common/lang');
    } catch (e) {
      console.error('Cannot load languages from backend.', e);
    }
    this.setLanguages(data);
    this.setLanguagesLoading(false);
  }

  setShowDoc(value: boolean) {
    this.showDoc = value;
  }

  @action
  setLanguagesLoading(value: boolean) {
    this.isLanguagesLoading = value;
  }

  @action
  setLanguages(value: ILangISO[], s?: string) {
    this.languages = value;
  }

  tServiceLangNameToISO(name: string): ILangISO | undefined {
    return this.tServiceLanguages?.find(l => name.toLowerCase() === l.tserviceName);
  }

  isRtlLanguage(langCode?: string): boolean {
    if (!langCode) {
      return false;
    }

    return this.rtlLanguages.includes(langCode);
  }

  @computed
  get rtlLanguages(): string[] {
    return this.languages ? this.languages.filter(l => l.rtl).map(l => l.code) : [];
  }

  @computed
  get tServiceLanguages(): ILangISO[] {
    return this.languages ? this.languages.filter(l => !!l.tserviceName) : [];
  }

  setPunctuationModelLanguages(languages: Language[]) {
    this.punctuationModelLanguages = languages;
  }

  async changePunctuationModelLanguages(languages: Language[]) {
    await settingsService.setValue(this.punctuationModelKey, languages);
  }

  async loadPunctuationModelLanguages(): Promise<Language[]> {
    try {
      const resp = await settingsService.getValue<{ punctuation_model_languages: Language[] }>(
        this.punctuationModelKey,
      );
      const languages = resp?.punctuation_model_languages || [];

      return languages;
    } catch (error) {
      return [];
    }
  }

  async initPunctuationModelLanguages(): Promise<void> {
    const punctuationModelResp = await this.loadPunctuationModelLanguages();
    if (punctuationModelResp) {
      const punctuationModelLanguages = Array.isArray(punctuationModelResp) ? punctuationModelResp : [];
      this.setPunctuationModelLanguages(punctuationModelLanguages);
    }
  }
  updateTableLabels() {
    this.tableInscriptions = {
      noData: i18next.t('tableLabels.noData'),
      settingsLabel: i18next.t('tableLabels.settingsTitleLabel'),
      settingsResetLabel: i18next.t('tableLabels.settingsResetLabel'),
      settingsOkLabel: i18next.t('tableLabels.settingsOkLabel'),
    };
  }
}

export const gAPP_STORE = new CAppStore();
