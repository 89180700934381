import { TRecordFields } from 'components/records/types';

import { IWordDictionary } from '../wordDictionary/WordDictionary.types';

export type TFilterFieldValue = string[] | string | number | boolean;
export type TFilterFieldType = 'text' | 'list' | 'datetime' | 'checkbox' | 'threshold' | 'number' | 'switch';
export type TFilterPredicate =
  | 'contains'
  | 'not_contains'
  | 'equal'
  | 'not_equal'
  | 'greater_than_equal'
  | 'less_than_equal';
export type TFilterSort = 'Asc' | 'Dsc';

export type TPreprocessingType = 'SIMPLE' | 'ZIP_JSON' | 'ZIP_ZOOM';
export const EXTRACTING_TYPE_LIST: TPreprocessingType[] = ['SIMPLE', 'ZIP_JSON', 'ZIP_ZOOM']; //['Audio', 'ZIP_json', 'ZIP_Zoom'];

//TODO: Хотя ID и указан обязательным полем, фактически таковым он не является, нужно выяснить и пофиксить
export interface IFilterField {
  id: TFilterDataFields;
  title: string;
  type: TFilterFieldType;
  value: TFilterFieldValue;
  score?: number;
  predicates?: TFilterPredicate[];
  predicate?: TFilterPredicate;
  // допускает ли поле установку нескольких значений. По умолчанию - true
  multiple?: boolean;
  newFilterBlock?: boolean;
  maxValues?: number;
}

export interface IFilterData {
  preprocessingType?: IFilterField;
  //Json-field
  idMetadata?: IFilterField;
  conversationIdMetadata?: IFilterField;
  nameMetadata?: IFilterField;
  nicknameMetadata?: IFilterField;
  sourceMetadata?: IFilterField;
  destinationMetadata?: IFilterField;
  startTimestampFromMetadata?: IFilterField; //*time
  startTimestampToMetadata?: IFilterField; //*time
  endTimestampFromMetadata?: IFilterField; //*time
  endTimestampToMetadata?: IFilterField; //*time
  durationMetadataFrom?: IFilterField; //@@ longIne?string
  durationMetadataTo?: IFilterField; //@@ longIne?string
  compressionRateMetadata?: IFilterField;
  criterionMetadata?: IFilterField;
  typeMetadata?: IFilterField;
  priorityMetadata?: IFilterField;
  locationMetadata?: IFilterField;
  imeiMetadata?: IFilterField;
  contentMetadata?: IFilterField;
  customMetadata?: IFilterField;
  //core-field
  userId?: IFilterField;
  actionType?: IFilterField;
  eventType?: IFilterField;
  message?: IFilterField;
  caseid?: IFilterField;
  createFrom?: IFilterField;
  createTo?: IFilterField;
  fileName?: IFilterField;
  languageResolved?: IFilterField;
  loadFrom?: IFilterField;
  loadTo?: IFilterField;
  meetingParticipants?: IFilterField;
  owner?: IFilterField;
  dictors?: IFilterField;
  speechDuration?: IFilterField;
  speechRecognition?: IFilterField;
  status?: IFilterField;
  textIdx?: IFilterField;
  topic?: IFilterField;
  topicThreshold?: IFilterField;
  translateIdx?: IFilterField;
  translationStatus?: IFilterField;
  wordsCount?: IFilterField;
  login?: IFilterField;
  privilege?: IFilterField;
  group_name?: IFilterField;
  groups_names?: IFilterField;
  topic_name?: IFilterField;
  topic_type?: IFilterField;
  template_name?: IFilterField;
  format?: IFilterField;
  channel_name?: IFilterField;
  filename?: IFilterField;
  dictor_name?: IFilterField;
  dictor_surname?: IFilterField;
  record_name?: IFilterField;
  processing_method?: IFilterField;
  loggedDatetime?: IFilterField;
  is_Kws?: IFilterField;
  is_auto_asr?: IFilterField;
  // summary Field
  summaryText?: IFilterField;
  summaryInstructions?: IFilterField;
  summaryTopics?: IFilterField;
}
export const FILTER_DATA_RELATIONS: Partial<Record<keyof IFilterData, keyof IFilterData>> = {
  createTo: 'createFrom',
  createFrom: 'createTo',
  loadTo: 'loadFrom',
  loadFrom: 'loadTo',
  startTimestampFromMetadata: 'startTimestampFromMetadata',
  startTimestampToMetadata: 'startTimestampToMetadata',
  endTimestampFromMetadata: 'endTimestampFromMetadata',
  endTimestampToMetadata: 'endTimestampToMetadata',
};
export type TFilterDataFields = keyof IFilterData;
export type TFilterDataFieldsWithCallFields = keyof IFilterData | keyof TRecordFields;

export interface IFilter {
  id: string;
  title: string;
  fixed: boolean;
  data: IFilterData;
}

export enum ERequestFilterPredicate {
  EQ = 'Eq',
  NOT_EQ = 'NotEq',
  GR = 'Gr',
  LESS = 'Less',
  EQ_GR = 'EqGr',
  EQ_LESS = 'EqLess',
  LIKE = 'Like',
  CONTAINS = 'Contains',
  IN = 'In',
  NOT_IN = 'NotIn',
}

export interface IRequestFilterField<T> {
  fieldName: T;
  predicate: ERequestFilterPredicate;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targetValues: any[];
}

type TJsonFieldFieldName = 'actionParameters';

export interface IFilterJsonField<T> {
  fieldName: TJsonFieldFieldName;
  key: T;
  predicate: ERequestFilterPredicate;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targetValues: any[];
}

export interface IRequestFilterData<T> {
  fields: IRequestFilterField<T>[];
  jsonFields?: IFilterJsonField<T>[];
  limit: number;
  offset: number;
  sortByField: {
    name: string;
    order: TFilterSort;
  };
  themes?: {
    ids: number[];
    threshold: number;
  };
  dictors?: IRequestFilterDataDictors;
  summaryFilter?: IRequestFilterSummary;
}

export interface IRequestFilterDataDictors {
  ids?: number[];
  meetingParticipants?: IValueAndPredicate;
  speechDuration?: IValueAndPredicate;
  wordsCount?: IValueAndPredicate;
}

export interface IRequestSummaryFilterField {
  value: string[];
  predicate: ERequestFilterPredicate;
}

export interface IRequestFilterSummary {
  text?: IRequestSummaryFilterField;
  topics?: IRequestSummaryFilterField;
  hasActions?: boolean;
}

export interface IValueAndPredicate {
  predicate: ERequestFilterPredicate;
  value: number;
}

export interface IFilterPhrase {
  text: string;
  words: string[];
}
export interface IFilterStoreData {
  currentFilterData: IFilterData;
  topicList: IWordDictionary[];
}
