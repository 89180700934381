import i18next from 'i18next';

import { IEventLogEntry } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { strToDatetimeStr } from '@/react-ui-kit/src/utils/dateUtils';

export const MessageEventLog = (actionType: number, actionParameters: string) => {
  const params = JSON.parse(actionParameters);

  let newActionParameters: { [key: string]: string } = { ...params };

  if (actionType === 11 || actionType === 12 || actionType === 13) {
    const action =
      params['is_auto_asr'] && params['is_kws']
        ? i18next.t('eventLog.is_auto_asr_and_kws')
        : params['is_auto_asr']
        ? i18next.t('eventLog.is_auto_asr')
        : params['is_kws']
        ? i18next.t('eventLog.is_kws')
        : '';
    newActionParameters = { ...newActionParameters, action1: action };
  }

  if (actionType === 36) {
    const processingMethod = i18next.t(`processing_method.${params['processing_method']}`);

    newActionParameters = { ...newActionParameters, processing_method: processingMethod };
  }

  newActionParameters['meeting_title_column_name'] =
    gAPP_STORE.meetingTitleColumnName === undefined
      ? i18next.t('records.meetingTitle')
      : (gAPP_STORE.meetingTitleColumnName as string);

  return i18next.t(
    `actionMessage.${gAPP_STORE.getEventLogStore().getEventDescription(actionType)}`,
    newActionParameters,
  );
};

export const UserEventLog = (row: IEventLogEntry) => {
  const userId = row.userId ? +row.userId : -1;

  return userId > -1 ? gAPP_STORE.getUsersStore().data.users.find(user => user.id === userId)?.email || '' : '';
};

export const LoggedDatetime = (value: string) => {
  return strToDatetimeStr(value);
};
