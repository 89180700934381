import { FilterTranslationStatusList, RecordStatusMap } from 'components/records/types';
import { observer } from 'mobx-react';
//import { reaction } from 'mobx';
import { useTranslation } from 'react-i18next';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { cloneDeep } from 'lodash';

import * as yup from 'yup';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { FilterCommon } from '../common/common-filter';

import { checkTrim } from '../records/utils/checkTrim';

import { handleFilterDate } from '../records/utils/handleFilterDate';

import { getDefaultFilter, getFilterDescriptor } from './filter-descriptor';
import { IFilterFieldListItem, strListToFilterFieldList } from './filter-field-list';
import {
  FILTER_DATA_RELATIONS,
  EXTRACTING_TYPE_LIST,
  TFilterDataFields,
  IFilterField,
  TFilterFieldValue,
  TFilterPredicate,
  IFilterData,
} from './types';

import { gAPP_STORE } from '@/app/app-store';
import { ESpecialLanguageCode, ILanguage } from '@/components/languages/i-language';

const defaultLanguagesInFilter: string[] = [
  ESpecialLanguageCode.EN,
  ESpecialLanguageCode.ES,
  ESpecialLanguageCode.RU,
  ESpecialLanguageCode.KK,
];
const fieldsToTrim = new Set([
  'textIdx',
  'translateIdx',
  'summaryText',
  'summaryTopics',
  'caseid',
  'caseId',
  'fileName',
  'idMetadata',
  'conversationIdMetadata',
  'nameMetadata',
  'nicknameMetadata',
  'sourceMetadata',
  'destinationMetadata',
  'compressionRateMetadata',
  'criterionMetadata',
  'typeMetadata',
  'priorityMetadata',
  'locationMetadata',
  'imeiMetadata',
  'contentMetadata',
  'customMetadata',
]);

type TFilterFields =
  | 'preprocessingType'
  | 'idMetadata'
  | 'conversationIdMetadata'
  | 'nameMetadata'
  | 'nicknameMetadata'
  | 'sourceMetadata'
  | 'destinationMetadata'
  | 'startTimestampFromMetadata'
  | 'startTimestampToMetadata'
  | 'endTimestampFromMetadata'
  | 'endTimestampToMetadata'
  | 'durationMetadataFrom'
  | 'durationMetadataTo'
  | 'compressionRateMetadata'
  | 'criterionMetadata'
  | 'typeMetadata'
  | 'priorityMetadata'
  | 'locationMetadata'
  | 'imeiMetadata'
  | 'contentMetadata'
  | 'customMetadata'
  | 'status'
  | 'translationStatus'
  | 'owner'
  | 'caseid'
  | 'fileName'
  | 'loadFrom'
  | 'loadTo'
  | 'createFrom'
  | 'createTo'
  | 'languageResolved'
  | 'textIdx'
  | 'translateIdx'
  | 'meetingParticipants'
  | 'dictors'
  | 'speechDuration'
  | 'wordsCount'
  | 'topic'
  | 'topicThreshold'
  | 'summaryText'
  | 'summaryInstructions'
  | 'summaryTopics';

interface IFilterProps {}

export const Filter: React.FC<IFilterProps> = observer(() => {
  const { t } = useTranslation();
  const dictorsStore = gAPP_STORE.getDictorsStore();
  const filterStore = gAPP_STORE.getFilterStore();

  const langT = useTranslation('lang')['t'];
  const formSchema = useMemo(() => {
    const testDateRange = (sFrom: string | undefined, sTo: string | undefined, isFrom: boolean): boolean => {
      const chekedValue = isFrom ? sFrom : sTo;
      if (!chekedValue) return true;

      const isCorrectRange = sFrom && sTo ? new Date(sFrom) <= new Date(sTo) : true;
      const notBiggerThanNow = chekedValue ? new Date(chekedValue) <= new Date() : true;
      const notTooOld = chekedValue ? new Date(chekedValue) >= new Date('1900-01-01 00:00:00') : true;

      return isCorrectRange && notBiggerThanNow && notTooOld;
    };
    const testRange = (sFrom: string | undefined, sTo: string | undefined, isFrom: boolean): boolean => {
      const chekedValue = isFrom ? sFrom : sTo;
      if (!chekedValue) return true;

      try {
        const isCorrectRange = sFrom && sTo ? BigInt(sFrom) <= BigInt(sTo) : true;

        return BigInt(chekedValue) > 0 && isCorrectRange;
      } catch {
        return false;
      }
    };

    return yup.object({
      preprocessingType: yup.array().max(3).optional(),
      //ZIP_JSON
      idMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      conversationIdMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      nameMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      nicknameMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      sourceMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      destinationMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      startTimestampFromMetadata: yup
        .string()
        .optional()
        .test(function (value) {
          return testDateRange(value, this.parent.startTimestampToMetadata, true);
        }),
      startTimestampToMetadata: yup
        .string()
        .optional()
        .test(function (value) {
          return testDateRange(this.parent.startTimestampFromMetadata, value, false);
        }),
      endTimestampFromMetadata: yup
        .string()
        .optional()
        .test(function (value) {
          return testDateRange(value, this.parent.endTimestampToMetadata, true);
        }),
      endTimestampToMetadata: yup
        .string()
        .optional()
        .test(function (value) {
          return testDateRange(this.parent.endTimestampFromMetadata, value, false);
        }),
      durationMetadataFrom: yup
        .array(
          yup
            .string()
            .max(50)
            .test(function (value) {
              return testRange(value, this.parent.durationMetadataFrom, true);
            }),
        )
        .max(1)
        .optional(),
      durationMetadataTo: yup
        .array(
          yup
            .string()
            .max(50)
            .test(function (value) {
              return testRange(this.parent.durationMetadataTo, value, true);
            }),
        )
        .max(1)
        .optional(),
      compressionRateMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      criterionMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      typeMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      priorityMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      locationMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      imeiMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      contentMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      customMetadata: yup.array(yup.string().max(100)).max(1).optional(),
      //Core
      status: yup.array().max(5),
      translationStatus: yup.array().max(5),
      owner: yup.array().max(5),
      caseid: yup.array(yup.string().max(1200).min(1)).max(10), //название встречи
      fileName: yup.array(yup.string().max(1200).min(1)).max(10),
      loadFrom: yup
        .string()
        .optional()
        .test(function (value) {
          return testDateRange(value, this.parent.loadTo, true);
        }),
      loadTo: yup
        .string()
        .optional()
        .test(function (value) {
          return testDateRange(this.parent.loadFrom, value, false);
        }),
      createFrom: yup
        .string()
        .optional()
        .test(function (value) {
          return testDateRange(value, this.parent.createTo, true);
        }),
      createTo: yup
        .string()
        .optional()
        .test(function (value) {
          return testDateRange(this.parent.createFrom, value, false);
        }),
      languageResolved: yup.array(yup.string().test(checkTrim)).max(10).optional(),
      textIdx: yup.array(yup.string().max(100).required()).max(10).optional(), //расшифровка
      translateIdx: yup.array(yup.string().max(100).required()).optional().max(10).optional(), //перевод
      meetingParticipants: yup.array(yup.string().max(100)).optional(), //количество дикторов
      dictors: yup.array().max(10).optional(),
      speechDuration: yup.array(yup.string().max(100)).optional(),
      wordsCount: yup.array(yup.string().max(100)).optional(),
      topic: yup.array(yup.string()).max(10).optional(),
      topicThreshold: yup.number().optional(),
      summaryText: yup.array(yup.string().min(1).max(1200)).optional(), //обзор совещания
      summaryInstructions: yup.boolean().optional(),
      summaryTopics: yup.array(yup.string().min(1).max(1200)).optional(), //на повестке дня
    });
  }, []);

  const {
    register,
    reset,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
  });

  useEffect(() => {
    filterStore.setFilterData(getDefaultFilter());
  }, [filterStore]);

  const setFilterData = useCallback(
    f => {
      filterStore.setFilterData(f());
    },
    [filterStore],
  );

  const applyFilter = useCallback(() => {
    gAPP_STORE.getRecordTextStore().clear();
    gAPP_STORE.videoStore.reset();
    gAPP_STORE.getRecordsStore().gridStore.setCurrentPage(0);
    gAPP_STORE.getRecordsStore().gridStore.resetSelected();
  }, []);

  const handleFilterClear = useCallback(() => {
    setFilterData(() => {
      setFilterData(getDefaultFilter);
      reset();
      applyFilter();

      return filterStore.Data;
    });
  }, [applyFilter, filterStore.Data, reset, setFilterData]);

  const getOwnersList = useCallback(() => {
    return gAPP_STORE.getUsersStore().data.users.map(u => ({ id: u.id.toString(), title: u.firstName }));
  }, []);
  const getTopicsList = useCallback(() => {
    return gAPP_STORE
      .getWordDictionaryStore()
      .data.wordDictionaryList.filter(wd => wd.isKws)
      .map(wd => ({ id: wd.id.toString(), title: wd.name }));
  }, []);
  const menuInstructions = useMemo(() => {
    const itemYes = t('records.summaryHaveTaskYes');
    const itemNo = t('records.summaryHaveTaskNo');

    return [
      { id: 'summaryHaveTaskYes', title: itemYes },
      { id: 'summaryHaveTaskNo', title: itemNo },
    ];
  }, [t]);
  const getInsructionsList = useCallback(() => {
    return menuInstructions;
  }, [menuInstructions]);
  const getDictorsList = useCallback(() => {
    const dictorNameList = dictorsStore.dictorsForFilter;
    const menuList: IFilterFieldListItem[] = [];
    dictorNameList.forEach((value: string, key: string) => {
      menuList.push({ id: key, title: value });
    });
    menuList.sort((a, b) => a.title.localeCompare(b.title));

    return menuList;
  }, [dictorsStore.dictorsForFilter]);

  const localizeLanguagesList = useCallback(
    (list: (string | ILanguage)[]): IFilterFieldListItem[] => {
      return list
        .map(l => {
          const isoLang = gAPP_STORE.tServiceLangNameToISO(typeof l === 'string' ? l : l.name);
          if (isoLang && isoLang.code) {
            return {
              id: `language.${isoLang.code}`,
              title: langT(isoLang.code),
            } as IFilterFieldListItem;
          }

          return { id: '', title: 'error' };
        })
        .filter(f => f.id !== '');
    },
    [langT],
  );

  const getLanguagesList = useCallback(() => {
    let items = localizeLanguagesList(gAPP_STORE.getLanguagesStore().data.languages.filter(l => l.installed));
    if (!items || items.length === 0) {
      console.warn('Attention! T-Service languages undefined! No languages in gAPP_STORE.LanguagesStore.');
      items = localizeLanguagesList(defaultLanguagesInFilter);
    }

    return items;
  }, [localizeLanguagesList]);
  const handleFilterChange = useCallback(
    async (id: string, pureValue: TFilterFieldValue | null | undefined, score?: number) => {
      const fixFromTo = (id: string, value: string, relatedKey: keyof IFilterData | undefined, isFrom: boolean) => {
        const val2: string | undefined = relatedKey && control._formValues[relatedKey];
        const date = handleFilterDate(isFrom ? { from: value, to: val2 } : { from: val2, to: value });
        if (isFrom) {
          date.from && (value = date.from);
        } else {
          date.to && (value = date.to);
        }
        const filterField = relatedKey && cloneDeep(filterStore.Data[relatedKey]);
        const isDoing = isFrom ? !!date.to : !!date.from;
        if (isDoing && filterField) {
          filterField.value = (isFrom ? date.to : date.from) as unknown as string;
          setValue(relatedKey as TFilterFields, filterField.value as string);
          relatedKey && relatedFields.push({ id: relatedKey, value: filterField });
        }
      };
      //#region Validation
      //#region date
      const prevValue = getValues()[id as TFilterFields];
      let value = pureValue;
      const relatedFields: { id: TFilterDataFields; value: IFilterField }[] = [];
      const relatedKey = FILTER_DATA_RELATIONS[id as TFilterDataFields];

      if (
        typeof value === 'string' &&
        ['loadFrom,createFrom,startTimestampFromMetadata,endTimestampFromMetadata'].includes(id)
      ) {
        fixFromTo(id, value, relatedKey, true);
      }
      if (
        typeof value === 'string' &&
        ['loadTo', 'createTo', 'startTimestampToMetadata,endTimestampToMetadata'].includes(id)
      ) {
        fixFromTo(id, value, relatedKey, false);
      }
      //#endregion
      if (fieldsToTrim.has(id) && Array.isArray(value)) {
        value = value.map(item => item.trim()).filter(Boolean);
      }
      setValue(id as TFilterFields, value as string);
      await trigger([id as TFilterFields]);

      if (control.getFieldState(id as TFilterFields).invalid && !relatedFields.length) {
        filterStore.onFilterFieldChange(id as TFilterFields);
        setValue(id as TFilterFields, prevValue);

        return;
      }

      //#endregion

      setFilterData(() => {
        const newFilterData = cloneDeep(filterStore.Data);
        relatedFields.forEach(relatedField => {
          newFilterData[relatedField.id] = relatedField.value;
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newFilterDataField: any = newFilterData[id as TFilterDataFields]
          ? newFilterData[id as TFilterDataFields]
          : {};

        if (Array.isArray(value)) {
          let goodvalues: string[];
          switch (id) {
            case 'preprocessingType':
              goodvalues = EXTRACTING_TYPE_LIST;
              break;
            case 'status':
              goodvalues = Array.from(RecordStatusMap.values());
              break;
            case 'translationStatus':
              const arr = Array.from(RecordStatusMap.values());
              goodvalues = arr.splice(1, arr.length - 2);
              break;
            case 'languageResolved':
              goodvalues = getLanguagesList().map(item => String(item.id));
              break;
            case 'owner':
              goodvalues = getOwnersList().map(item => item.id);
              break;
            case 'dictors':
              goodvalues = getDictorsList().map(item => String(item.id));
              break;
            case 'summaryText':
              goodvalues = value.slice(0, 20);
              break;

            case 'topic':
              goodvalues = getTopicsList().map(item => item.id);
              break;

            default:
              goodvalues = value;
          }
          newFilterDataField.value = value.filter(v => goodvalues.includes(v));
        } else {
          newFilterDataField.value = value;
        }
        newFilterDataField.score = score;

        return newFilterData;
      });
    },
    [
      control,
      getTopicsList,
      filterStore,
      getDictorsList,
      getLanguagesList,
      getOwnersList,
      getValues,
      setFilterData,
      setValue,
      trigger,
    ],
  );

  const handleFilterPredicateChange = useCallback(
    (id: string, value: TFilterPredicate) => {
      setFilterData(() => {
        const newFilterData = filterStore.Data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newFilterDataField: any = newFilterData[id as TFilterDataFields]
          ? newFilterData[id as TFilterDataFields]
          : {};

        newFilterDataField.predicate = value;

        return newFilterData;
      });
    },
    [filterStore.Data, setFilterData],
  );

  const handleFilterApply = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        applyFilter();
      }
    },
    [applyFilter],
  );

  const getNoTranslateValue = useCallback((descriptor: IFilterField): boolean | undefined => {
    let result = undefined;
    if (descriptor.id === 'dictors' || descriptor.id === 'topic') {
      result = true;
    }

    return result;
  }, []);

  const getFilterFieldList = useCallback(
    (descriptor: IFilterField): IFilterFieldListItem[] => {
      let result: IFilterFieldListItem[] = [];

      switch (descriptor.id) {
        case 'preprocessingType':
          result = strListToFilterFieldList(EXTRACTING_TYPE_LIST);
          break;
        case 'status':
          result = strListToFilterFieldList(
            Array.from(RecordStatusMap.values()).filter(v => !['recordStatus.unknown'].includes(v)),
          );
          break;

        case 'translationStatus':
          result = strListToFilterFieldList(
            Array.from(RecordStatusMap.values()).filter(v => FilterTranslationStatusList.includes(v)),
          );
          break;

        case 'languageResolved':
          result = getLanguagesList();
          break;

        case 'owner':
          result = getOwnersList();
          break;

        case 'topic':
          result = getTopicsList();
          break;

        case 'dictors':
          result = getDictorsList();
          break;

        case 'summaryInstructions':
          result = getInsructionsList();
          break;
      }

      return result;
    },
    [getLanguagesList, getOwnersList, getTopicsList, getDictorsList, getInsructionsList],
  );

  const [filterFields, setFilterFields] = useState<IFilterField[]>([]);
  useEffect(() => {
    const config = {
      viewTranslate: gAPP_STORE.viewTranslate,
      avocado_topics: gAPP_STORE.avocado_topics,
      gridProxyUrl: gAPP_STORE.gridProxyUrl,
      showSummaryAI: gAPP_STORE.showSummaryAI,
      zip_Json: gAPP_STORE.zip_Json,
    };
    const descr = getFilterDescriptor(config);
    if (descr.length !== filterFields.length) {
      setFilterFields(descr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gAPP_STORE.viewTranslate,
    gAPP_STORE.avocado_topics,
    gAPP_STORE.gridProxyUrl,
    gAPP_STORE.showSummaryAI,
    gAPP_STORE.zip_Json,
  ]);
  // useEffect(
  //   () =>
  //     reaction(
  //       () => ({
  //         viewTranslate: gAPP_STORE.viewTranslate,
  //         avocado_topics: gAPP_STORE.avocado_topics,
  //         gridProxyUrl: gAPP_STORE.gridProxyUrl,
  //         showSummaryAI: gAPP_STORE.showSummaryAI,
  //         zip_Json: gAPP_STORE.zip_Json,
  //       }),
  //       (newConfig: IFilterConfigKeys, oldConfig: IFilterConfigKeys) => {
  //         if (JSON.stringify(newConfig) !== JSON.stringify(oldConfig)) {
  //           const descr = getFilterDescriptor(newConfig);
  //           setFilterFields(descr);
  //         }
  //       },
  //     ),
  //   [],
  // );

  return (
    <>
      <FilterCommon
        getFilterFieldList={getFilterFieldList}
        filterFields={filterFields}
        handleFilterChange={handleFilterChange}
        handleFilterPredicateChange={handleFilterPredicateChange}
        handleKeyDown={handleKeyDown}
        getNoTranslateValue={getNoTranslateValue}
        filterData={filterStore.Data}
        handleFilterApply={handleFilterApply}
        handleFilterClear={handleFilterClear}
      />
    </>
  );
});
