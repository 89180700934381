import { observer } from 'mobx-react';

import { Box } from '@material-ui/core';

import { DialogStore } from './dialog.store';
import { ItemCaseName } from './items/itemCaseName';
import { ItemExtractType } from './items/itemExtractType';
import { ItemFiles } from './items/itemFiles';
import { ItemGroups } from './items/itemGroups';
import { ItemHotlists } from './items/itemHotlists';
import { ItemLabel } from './items/itemLabel';
import { ItemLanguages } from './items/itemLanguages';
import { ItemSeparation } from './items/itemSeparation';

import { gAPP_STORE } from '@/app/app-store';

export interface IDialogBody {
  data: DialogStore;
}

export const DialogBody = observer(({ data: dialogResult }: IDialogBody) => {
  const items = (
    <>
      <ItemCaseName data={dialogResult} mettingTitle={dialogResult.mettingTitle} />
      <ItemExtractType data={dialogResult} />
      <ItemFiles data={dialogResult} />
      <ItemGroups data={dialogResult} />
      {gAPP_STORE.showChangeLabelsButton && <ItemLabel store={dialogResult} />}
      <ItemHotlists data={dialogResult} />
      <ItemSeparation data={dialogResult} />
      <ItemLanguages data={dialogResult} />
    </>
  );

  return (
    <Box style={{ padding: '1em' }}>
      <Box width={600} style={{ gap: '3em' }}>
        {items}
      </Box>
    </Box>
  );
});
