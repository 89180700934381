import { Checkbox, makeStyles, Switch } from '@material-ui/core';
import { trimStart } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterFieldList, IFilterFieldListItem } from './filter-field-list';
import FilterPredicate from './filter-predicate';
import { FilterSlider } from './filter-slider';
import { fixFilterValue } from './filter-utils';
import { IFilterData, IFilterField, TFilterFieldValue, TFilterPredicate } from './types';

import { date2str } from '@/common/utils';
import FilterFieldDatetime from '@/components/filter/filter-field-datetime';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'stretch',
  },
  predicate: {
    borderRight: '1px solid #ddd',
    flexGrow: 0,
    padding: '4px 10px',
    alignSelf: 'center',
  },
  field: {
    flexGrow: 1,
    paddingTop: 1,
    paddingLeft: 3,
  },

  input: {
    border: 'none',
    outline: 'none',
    width: '100%',
  },
  textField: {
    border: 'none',
    outline: 'none',
  },
});

interface IFilterFieldProps {
  filterData?: IFilterData;
  value?: TFilterFieldValue;
  score?: number;
  predicate?: TFilterPredicate;
  list?: IFilterFieldListItem[];
  descriptor: IFilterField;
  onChange: (descriptorId: string, value: TFilterFieldValue | null | undefined, score?: number) => void;
  onChangePredicate?: (descriptorId: string, value: TFilterPredicate) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  noTranslateValue?: boolean;
  multiple?: boolean;
}

const FilterField: React.FC<IFilterFieldProps> = props => {
  const {
    value,
    score,
    predicate,
    list,
    descriptor,
    onChange,
    onChangePredicate,
    onKeyDown,
    noTranslateValue,
    multiple,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const normalizedDescriptorId = descriptor.id.toString();

  const handleCheckboxChange = useCallback(
    event => {
      if (onChange) {
        onChange(normalizedDescriptorId, event.target.checked);
      }
    },
    [normalizedDescriptorId, onChange],
  );

  const handleDatetimeChange = useCallback(
    (name: string, date: Date | null) => {
      if (onChange) {
        const sDate = date !== null && date.toDateString() !== 'Invalid Date' ? date2str(date) : undefined;
        onChange(normalizedDescriptorId, sDate);
      }
    },
    [normalizedDescriptorId, onChange],
  );

  const handleListChange = useCallback(
    (name: string, value: string[], score?: number) => {
      if (onChange) {
        onChange(normalizedDescriptorId, value, score);
      }
    },
    [onChange, normalizedDescriptorId],
  );

  const handleNumberListChange = useCallback(
    (name: string, value: string[], score?: number) => {
      const valueFormatted: string[] = value
        .map(v => (v.length > 1 ? trimStart(v, '0') : v))
        .filter(v => !isNaN(Number(v)));
      handleListChange(name, valueFormatted, score);
    },
    [handleListChange],
  );

  const handleChangePredicate = useCallback(
    (value: TFilterPredicate) => {
      if (onChangePredicate) {
        onChangePredicate(normalizedDescriptorId, value);
      }
    },
    [onChangePredicate, normalizedDescriptorId],
  );

  const handleSliderChange = useCallback(
    (newValue: number) => {
      onChange(normalizedDescriptorId, newValue);
    },
    [onChange, normalizedDescriptorId],
  );

  const noTranslateValueList = [
    'userId',
    'topic_name',
    'channel_name',
    'login',
    'owner',
    'group_name',
    'preprocessingType',
  ].includes(normalizedDescriptorId)
    ? true
    : noTranslateValue;

  const fieldComponent = useMemo(() => {
    switch (descriptor.type) {
      case 'checkbox':
        return (
          <Checkbox
            color="primary"
            name={normalizedDescriptorId}
            checked={Boolean(value)}
            onChange={handleCheckboxChange}
            onKeyDown={onKeyDown}
          />
        );

      case 'switch':
        return (
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <Switch checked={Boolean(value)} onChange={handleCheckboxChange} />
          </div>
        );

      case 'datetime':
        return (
          <FilterFieldDatetime
            name={normalizedDescriptorId}
            value={(value as string) && (value as string).length > 0 ? (value as string) : null}
            onKeyDown={onKeyDown}
            handleChange={date => handleDatetimeChange(normalizedDescriptorId, date)}
          />
        );

      case 'list':
        return (
          <FilterFieldList
            name={normalizedDescriptorId}
            value={value ? ([value].flat() as string[]) : []}
            score={score}
            list={list}
            onChange={handleListChange}
            noTranslateValue={noTranslateValueList}
            multiple={multiple}
          />
        );

      case 'threshold':
        return <FilterSlider defaultValue={value as number} change={handleSliderChange} min={1} max={100} step={1} />;

      case 'number':
        return (
          <FilterFieldList
            name={normalizedDescriptorId}
            score={score}
            value={fixFilterValue(value as string[])}
            onChange={handleNumberListChange}
            noTranslateValue={true}
            multiple={multiple}
          />
        );

      default:
        return (
          <FilterFieldList
            name={normalizedDescriptorId}
            value={fixFilterValue(value as string[])}
            onChange={handleListChange}
            noTranslateValue={true}
          />
        );
    }
  }, [
    descriptor.type,
    normalizedDescriptorId,
    value,
    handleCheckboxChange,
    onKeyDown,
    score,
    list,
    handleListChange,
    noTranslateValueList,
    multiple,
    handleSliderChange,
    handleNumberListChange,
    handleDatetimeChange,
  ]);

  return (
    <div className={classes.root}>
      {descriptor.predicates && (
        <div className={classes.predicate}>
          <FilterPredicate descriptor={descriptor} predicate={predicate} onChange={handleChangePredicate} />
        </div>
      )}
      <div className={classes.field}>{fieldComponent}</div>
    </div>
  );
};

export default FilterField;
