import React, { CSSProperties, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Avatar, Chip, makeStyles } from '@material-ui/core';
import { SlUser, SlUserFemale } from 'react-icons/sl';

import { TDictorChipDisableMode } from './types';

import { useTextColor } from '@/react-ui-kit/src/hooks/useTextColor';

import { IDictor } from '@/types/dictors';
import appColors from '@/app/app-colors';
import { gAPP_STORE } from '@/app/app-store';
import { gridStorageService } from '@/services/grid-storage/grid-storage-service';
import { getShortTitleFromDictor, isRTLLocal } from '@/components/dictors/dictor-utils';

const useStyles = makeStyles(theme => ({
  chipRtl: {
    '& .MuiChip-deleteIcon': {
      marginRight: '-6px',
      marginLeft: '5px',
    },
  },
}));

interface IDictorChipProps {
  dictor: IDictor;
  style?: CSSProperties;
  disableMode?: TDictorChipDisableMode;
  popperStyle?: CSSProperties;
}

export const DictorChipLight: React.FC<IDictorChipProps> = observer(props => {
  const classes = useStyles();

  const { dictor, style } = props;

  const recordsStore = gAPP_STORE.getRecordsStore();
  const record = recordsStore.gridStore.selectedRow;

  const { isTranslation } = gAPP_STORE.getRecordTextStore();

  const recordLanguage = isTranslation ? record?.translateLanguage : record?.languageResolved;
  const isRTL = gAPP_STORE.isRtlLanguage(recordLanguage);

  const { textColor, backgroundColor, avatarOpacity, border, cursor } = useMemo(() => {
    const notRealDictor = dictor.segmentation.ranges.length === 0;

    const backgroundColor = dictor.__color;
    const textColor = useTextColor(backgroundColor);
    const avatarOpacity = undefined;
    const border = notRealDictor ? `solid 2px ${appColors.grey}` : `solid 2px ${dictor.__color}`;
    const cursor = notRealDictor ? 'default' : 'pointer';

    return { textColor, backgroundColor, avatarOpacity, border, cursor };
  }, [dictor.__color, dictor.segmentation.ranges.length]);

  const labelText = useMemo(() => {
    const dictorName = getShortTitleFromDictor(dictor);
    const probability = dictor.relevance
      ? isRTLLocal(dictorName)
        ? `(%${dictor.relevance.toFixed(2)})`
        : `(${dictor.relevance.toFixed(2)}%)`
      : '';

    return `${dictorName} ${probability}`;
  }, [dictor]);

  const avatarRtlStyle = useMemo(() => (isRTL ? { marginRight: '5px', marginLeft: '-6px' } : undefined), [isRTL]);
  const avatarUrl = useMemo(
    () => (dictor.avatar ? gridStorageService.getNginxUrl(dictor.recordId, dictor.avatar) : ''),
    [dictor.avatar, dictor.recordId],
  );
  const genderIcon = useMemo(() => {
    dictor.gender === 'female' ? <SlUserFemale size={'65%'} /> : <SlUser size={'65%'} />;
  }, [dictor.gender]);

  return (
    <Chip
      className={isRTL ? classes.chipRtl : undefined}
      avatar={
        <Avatar alt={labelText} src={avatarUrl} style={{ opacity: avatarOpacity, ...avatarRtlStyle }}>
          {dictor.gender !== 'undefined' ? genderIcon : undefined}
        </Avatar>
      }
      label={<>{labelText}</>}
      style={{
        ...style,
        color: textColor,
        backgroundColor,
        border,
        cursor,
        direction: isRTL ? 'rtl' : undefined,
      }}
    />
  );
});
