import { makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import { runInAction } from 'mobx';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogStore } from '../dialog.store';

import { Stack } from './Stack';

import { gAPP_STORE } from '@/app/app-store';
import { EXTRACTING_TYPE_LIST, TPreprocessingType } from '@/components/filter/types';

const useStyles = makeStyles(theme => ({
  requiredLabel: {
    position: 'relative',
    marginTop: '0.7em',
    '&::after': {
      content: '"*"',
      color: 'red',
      marginLeft: '0.21em',
    },
  },
}));

const labelList = ['Audio/Archive', 'Audio', 'ZIP_Json', 'ZIP_Zoom'];
export interface IItemExtractType {
  data: DialogStore;
}

export const ItemExtractType: FC<IItemExtractType> = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [extractingType, setExtractingType] = useState(data.preprocessingType);

  const changeExtractingType = useCallback(
    (value: string) => {
      runInAction(() => {
        data.setExtractType(value as unknown as TPreprocessingType);
        setExtractingType(value as unknown as TPreprocessingType);
      });
    },
    [data],
  );

  const menulist = useMemo(() => {
    const defaultLabel = gAPP_STORE.zip_Simple ? labelList[0] : labelList[1];
    const result = [{ key: EXTRACTING_TYPE_LIST[0], name: defaultLabel }];
    if (gAPP_STORE.zip_Json) {
      result.push({ key: EXTRACTING_TYPE_LIST[1], name: labelList[2] });
    }
    if (gAPP_STORE.zip_ZOOM) {
      result.push({ key: EXTRACTING_TYPE_LIST[2], name: labelList[3] });
    }

    return result;
  }, []);

  const blockSelectExtractingType = (
    <Stack direction="column">
      <Stack direction="row" alignItems="center" spacing={0}>
        <Typography className={classes.requiredLabel}>{t('records.preprocessingType')}</Typography>
      </Stack>
      <TextField
        disabled={menulist.length === 1}
        variant="outlined"
        fullWidth
        select
        value={extractingType}
        onChange={event => changeExtractingType(event.target.value)}
      >
        {menulist.map(value => (
          <MenuItem key={value.key} value={value.key}>
            {value.name}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );

  return <Stack spacing={1}>{blockSelectExtractingType}</Stack>;
};
