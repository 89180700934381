/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

export const array2str = (arr: any[], field: any = null) => {
  if (!arr) {
    return '';
  }

  return field ? arr.map(e => e[field]).join(', ') : arr.join(', ');
};

export const arrayIntersection = (a: any, b: any) => a.filter((n: any) => b.indexOf(n) !== -1);

export const arrayUniqueValues = (arr: any[]) => arr.filter((d: any, i: any, a: any) => a.indexOf(d) === i);

export const isString = (el: any) => {
  return typeof el === 'string' || el instanceof String;
};

export const dstr2str = (d: string) => moment(new Date(Date.parse(d))).format('YYYY-MM-DD HH:mm');

export const map2str = (map: any) => {
  return JSON.stringify([...map.entries()]);
};

export const str2map = (str: any) => {
  return JSON.parse(str).reduce((m: any, [key, val]: [any, any]) => m.set(key, val), new Map());
};

export const translateLanguageByCode = (code: string, targetLang: string) => {
  return new Intl.DisplayNames(targetLang, { type: 'language' }).of(code);
};

export const parseUrl = (url: string) => {
  const result: string[] = [];
  const regex = /[a-z]+=[%\-a-zA-Zа-яА-Я0-9,_]+/gm;
  // const regex = /[a-z]+=[a-z0-9,]+/gm;
  let m;
  while ((m = regex.exec(url)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach(match => {
      const item: any[] = match.split('=');
      result[item[0]] = item[1];
    });
  }

  return result;
};

export const parseDurationSec = (value: any) => {
  if (!value) {
    return 0;
  }

  if (typeof value === 'string' && value.match(/^([0-9]{2})[:.]*/)) {
    try {
      const time: any[] = value.match(/(\d+)/g) || [];
      const hours = parseInt(time[0]) || 0;
      const min = parseInt(time[1]) || 0;
      const sec = parseInt(time[2]) || 0;

      return hours * 60 * 60 + min * 60 + sec;
    } catch (ex) {
      console.error('Cannot parse call duration: ', value);

      return 0;
    }
  }

  return +value;
};

export const secToStr = (durationSec: number) => {
  if (!durationSec || durationSec === 0) {
    return '';
  }
  const hours = Math.floor(durationSec / 60 / 60);
  const minutes = Math.floor(durationSec / 60) - hours * 60;
  const seconds = durationSec % 60;

  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ].join(':');
};

export const parseSexScore = (value: any) => {
  if (!value) {
    return 0;
  }

  return +value;
};

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const pad = (num: number, places: number) => String(num).padStart(places, '0');

export const date2str = (date: Date | null | undefined): string | null | undefined => {
  if (date === null) return null;
  if (date === undefined) return undefined;

  return `${date.getFullYear()}-${pad(date.getMonth() + 1, 2)}-${pad(date.getDate(), 2)} ${pad(
    date.getHours(),
    2,
  )}:${pad(date.getMinutes(), 2)}:${pad(date.getSeconds(), 2)}`;
};

export const monthDays = (d: Date) => new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();

export const dateAddDays = (d: Date, days: number) => {
  const newDate = new Date(d);
  newDate.setTime(newDate.getTime() + days * 24 * 60 * 60 * 1000);

  return newDate;
};

export const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
export const deCapitalizeFirstLetter = (s: string) => s.charAt(0).toLowerCase() + s.slice(1);

export const toTruncatedString = (s: string, maxLength: number) =>
  s.length > maxLength ? `${s.substring(0, maxLength)}…` : s;

export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = error => reject(error);
  });
