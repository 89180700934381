import React, { CSSProperties, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { BoxProps, Chip, makeStyles, TextField } from '@material-ui/core';
import { Clear, MoreVert } from '@material-ui/icons';

import { SlUser, SlUserFemale } from 'react-icons/sl';

import { ERecordStatus } from '../records/types';

import { CachedAvatar, ImageCacheType } from '../avatar/cached-avatar';
import { DictorAvatar } from '../avatar/dictor-avatar';

import { ICreateCardProps } from '../person-cards/types';

import { DictorChipMenu, IDictorChipMenuProps } from './DictorChipMenu';
import { DictorChipStore } from './DictorChipStore';
import { DictorPopper } from './DictorPopper';
import { TDictorChipDisableMode } from './types';

import { useTextColor } from '@/react-ui-kit/src/hooks/useTextColor';
import { IDictor } from '@/types/dictors';

import appColors from '@/app/app-colors';
import { gAPP_STORE } from '@/app/app-store';
import { MAX_DICTOR_NAME_LENGTH } from '@/common/constants';
import { dictorReset, getShortTitleFromString, getTitle, isRTLLocal } from '@/components/dictors/dictor-utils';
import { EventLogService, TInformDictorCard } from '@/components/eventLog/event-log-service';
import { gridStorageService } from '@/services/grid-storage/grid-storage-service';

const useStyles = makeStyles(theme => ({
  chipRtl: {
    '& .MuiChip-deleteIcon': {
      marginRight: '-6px',
      marginLeft: '5px',
    },
  },
}));

interface IDictorChipProps {
  dictor: IDictor;
  style?: CSSProperties;
  disableMode?: TDictorChipDisableMode;
  popperStyle?: CSSProperties;
  cache?: ImageCacheType;
}

//#region iInnerFiled
interface IInnerField {
  innerValue: string;
  editMode: boolean;
  handleDictorNameChange: (name: string, oldName: string) => void;
  probability: string;
}

interface IUseInnerField {
  editMode: boolean;
  dictor: IDictor;
  changeEditMode: (newEditMode?: boolean) => void;
  updateDictor: (dictor: IDictor) => Promise<void>;
  isDefaultName: (name: string) => boolean;
}

const useInnerField = (props: IUseInnerField): IInnerField => {
  const { t } = useTranslation();
  const { editMode, changeEditMode, dictor, updateDictor, isDefaultName } = props;

  let dictorName = getTitle(dictor);
  // для чипса в режиме НЕредактивания ограничим видимое количество символов в имени
  if (!editMode) {
    dictorName = getShortTitleFromString(dictorName);
  }

  const probability = dictor.relevance
    ? isRTLLocal(dictorName)
      ? `(%${dictor.relevance.toFixed(2)})`
      : `(${dictor.relevance.toFixed(2)}%)`
    : '';

  const handleDictorNameChange = useCallback(
    (name: string, oldName: string) => {
      const newName = name.trim();
      if (newName === oldName) {
        changeEditMode();

        return;
      }
      if (newName === '') {
        runInAction(() => {
          dictorReset(dictor);
        });
      } else {
        if (isDefaultName(newName)) {
          changeEditMode();
          toast.error(t('dictors.defaultName'));

          return;
        }
        const fio = newName.split(' ');
        runInAction(() => {
          // все символы до первого пробела попадают в name, остальное - в surname
          dictor.name = fio.length > 0 ? fio[0] : newName;
          dictor.surname =
            fio.length > 1
              ? fio
                  .slice(1)
                  .reduce((prev, curr) => `${prev} ${curr}`, '')
                  .trim()
              : '';
          dictor.defaultName = false;
        });
      }

      updateDictor(dictor);
      EventLogService.informDictorCard(dictor, TInformDictorCard.rename);
      changeEditMode();
    },
    [changeEditMode, dictor, isDefaultName, t, updateDictor],
  );

  return {
    editMode,
    innerValue: dictorName,
    handleDictorNameChange,
    probability,
  };
};

const InnerField = (props: IInnerField): JSX.Element => {
  const { innerValue, editMode, handleDictorNameChange, probability } = props;
  const ref = useRef<HTMLInputElement>();

  const onEnterKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        // сохраняем введенное значение
        handleDictorNameChange(ref.current?.value ?? innerValue, innerValue);
      } else if (event.key === 'Escape') {
        // отменяем изменения
        handleDictorNameChange(innerValue, innerValue);
      }
    },
    [handleDictorNameChange, innerValue],
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [editMode]);

  return (
    <>
      {editMode ? (
        <TextField
          onBlur={() => handleDictorNameChange(ref.current?.value ?? innerValue, innerValue)}
          onKeyDown={onEnterKeyDown}
          defaultValue={innerValue}
          inputRef={ref}
          inputProps={{ maxLength: MAX_DICTOR_NAME_LENGTH }}
        />
      ) : (
        `${innerValue} ${probability}`
      )}
    </>
  );
};
//#endregion

//#region DictorChipMenu
interface IUseDictorChipMenu {
  recordId?: number;
  dictorChipStore: DictorChipStore;
  clearSearchResult: (dictor: IDictor, recordId: number) => Promise<void>;
  createCard: (props: ICreateCardProps) => Promise<void>;
  dictor: IDictor;
  searchCards: (maxParticipants: number, dictor?: IDictor) => Promise<void>;
}

const useDictorChipMenu = (props: IUseDictorChipMenu): IDictorChipMenuProps => {
  const { recordId, dictorChipStore, clearSearchResult, dictor, createCard, searchCards } = props;
  const [editMode, setEditMode] = useState(false);

  const changeEditMode = (newEditMode?: boolean) => {
    setEditMode(newEditMode === undefined ? editMode => !editMode : newEditMode);
  };

  const editMode2 = gAPP_STORE.editingASR;
  const recordStore = gAPP_STORE.getRecordsStore();
  const badStatus =
    recordStore.gridStore.selectedRow !== undefined
      ? [ERecordStatus.PREPROCESSED, ERecordStatus.PROCESSING, ERecordStatus.FAILED].includes(
          recordStore.gridStore.selectedRow?.voiceModelStatus,
        )
      : false;
  const isDictorActionPermit = editMode2 && !badStatus;

  const clearResults = useCallback(() => {
    if (recordId) {
      const dictorUpdate = {
        ...dictor,
        groupsId: [],
      };
      clearSearchResult(dictorUpdate, recordId);
      EventLogService.informDictorCard(dictor, TInformDictorCard.delete);
    }
  }, [clearSearchResult, dictor, recordId]);

  const handleCreateCard = useCallback(async () => {
    try {
      const accessGroups = recordStore.selectedRecords[0].groups?.map(g => g.id);
      await createCard({ dictor, accessGroups });
    } catch (e) {
      toast.error(e);
      console.log(e);
    }
  }, [createCard, dictor, recordStore.selectedRecords]);

  const handleSearchSpeakers = useCallback(async () => {
    if (!dictor.modelRef) {
      return;
    }
    try {
      await searchCards(1, dictor);
    } catch (e) {
      toast.error(e);
      console.log(e);
    }
  }, [searchCards, dictor]);

  return {
    dictorChipStore,
    editMode,
    isDictorActionPermit,
    changeEditMode,
    clearResults,
    createCard: handleCreateCard,
    searchSpeakers: handleSearchSpeakers,
  };
};
//#endregion

export const DictorChip: React.FC<IDictorChipProps & BoxProps> = observer(props => {
  const classes = useStyles();

  const { dictor, style, disableMode, popperStyle, cache } = props;

  const dictorsStore = gAPP_STORE.getDictorsStore();
  const personCardsStore = gAPP_STORE.getPersonCardsStore();
  const recordsStore = gAPP_STORE.getRecordsStore();
  const { createCard, searchCards } = personCardsStore;
  const {
    toggleHideSegmentation,
    toggleHidePhrases,
    updateDictor,
    isDefaultName,
    clearSearchResult,
    hidePhrases,
    hideSegmentation,
    deleteDictor,
  } = dictorsStore;
  const record = recordsStore.gridStore.selectedRow;

  const dictorChipStore = useMemo(() => new DictorChipStore(dictor), [dictor]);
  const { setAnchorEl, handleMouseEnter, handleMouseLeave, setOpenMenu } = dictorChipStore;

  const { isTranslation } = gAPP_STORE.getRecordTextStore();

  const recordLanguage =
    isTranslation && record?.translateLanguage ? record?.translateLanguage : record?.languageResolved;
  const isRTL = gAPP_STORE.isRtlLanguage(recordLanguage);

  const handleClickMenu = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (dictor.segmentation.ranges.length === 0) {
        deleteDictor(dictor.id);
      } else {
        event.stopPropagation();
        setOpenMenu(true);
      }
    },
    [setOpenMenu, dictor.segmentation.ranges.length, dictor.id, deleteDictor],
  );

  const { backgroundColor, avatarOpacity, border, cursor, ActionIcon } = useMemo(() => {
    const notRealDictor = dictor.segmentation.ranges.length === 0;
    const chipDisabled =
      (disableMode === 'segmentation' && hideSegmentation.includes(dictor.id)) ||
      (disableMode === 'phrases' && hidePhrases.includes(dictor.id));

    const backgroundColor = notRealDictor || chipDisabled ? appColors.grey : dictor.__color;
    const avatarOpacity = chipDisabled ? 0.5 : undefined;
    const border = notRealDictor ? `solid 2px ${appColors.grey}` : `solid 2px ${dictor.__color}`;
    const cursor = notRealDictor ? 'default' : 'pointer';
    const ActionIcon = notRealDictor ? Clear : MoreVert;

    return { backgroundColor, avatarOpacity, border, cursor, ActionIcon };
  }, [dictor.__color, hidePhrases, hideSegmentation, disableMode, dictor.id, dictor.segmentation.ranges.length]);

  const textColor = useTextColor(backgroundColor);

  const dictorChipMenu = useDictorChipMenu({
    recordId: record?.id,
    dictorChipStore,
    clearSearchResult,
    dictor,
    createCard,
    searchCards,
  });

  const innerField = useInnerField({
    ...dictorChipMenu,
    dictor,
    updateDictor,
    isDefaultName,
  });

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      if (dictor.segmentation.ranges.length === 0) {
        return;
      }

      if (!dictorChipMenu.editMode) {
        switch (disableMode) {
          case 'segmentation':
            return toggleHideSegmentation(dictor.id);
          case 'phrases':
            return toggleHidePhrases(dictor.id);
        }
      }
    },
    [
      dictor.id,
      dictorChipMenu.editMode,
      dictor.segmentation.ranges.length,
      disableMode,
      toggleHidePhrases,
      toggleHideSegmentation,
    ],
  );

  const avatarRtlStyle = useMemo(() => (isRTL ? { marginRight: '5px', marginLeft: '-6px' } : undefined), [isRTL]);
  const avatarUrl = dictor.avatar ? gridStorageService.getNginxUrl(dictor.recordId, dictor.avatar) : '';
  const genderIcon = dictor.gender === 'female' ? <SlUserFemale size={'65%'} /> : <SlUser size={'65%'} />;

  return (
    <>
      <Chip
        ref={ref => setAnchorEl(ref)}
        className={isRTL ? classes.chipRtl : undefined}
        avatar={
          cache ? (
            <CachedAvatar
              isDefaultName={dictor.defaultName}
              imageCache={cache}
              alt={`${innerField.innerValue} ${innerField.probability}`}
              src={avatarUrl}
              style={{ opacity: avatarOpacity, ...avatarRtlStyle }}
            >
              {dictor.gender !== 'undefined' ? genderIcon : undefined}
            </CachedAvatar>
          ) : (
            <DictorAvatar
              isDefaultName={dictor.defaultName}
              alt={`${innerField.innerValue} ${innerField.probability}`}
              src={avatarUrl}
              style={{ opacity: avatarOpacity, ...avatarRtlStyle }}
            >
              {dictor.gender !== 'undefined' ? genderIcon : undefined}
            </DictorAvatar>
          )
        }
        label={<InnerField {...innerField} />}
        style={{
          ...style,
          color: textColor,
          backgroundColor,
          border,
          cursor,
          direction: isRTL ? 'rtl' : undefined,
        }}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        deleteIcon={<ActionIcon onMouseEnter={handleMouseEnter} />}
        onDelete={handleClickMenu}
      />
      {!dictor.disabledForEditing && (
        <DictorPopper style={popperStyle} dictorChipStore={dictorChipStore} avatarCache={cache} />
      )}
      <DictorChipMenu style={popperStyle} {...dictorChipMenu} />
    </>
  );
});
