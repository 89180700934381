import { FC, useMemo } from 'react';
import { Avatar, AvatarProps, Badge, useTheme } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import Edit from '@material-ui/icons/Edit';

import { isString } from '@/common/utils';
import { ukColors } from '@/react-ui-kit/src';

export type ImageCacheType = { [url: string]: string };

export interface IDictorAvatar extends AvatarProps {
  isDefaultName: boolean;
}

export const DictorAvatar: FC<IDictorAvatar> = props => {
  const { isDefaultName, src, alt, ...rest } = props;

  const size: number | undefined =
    rest.style && rest.style.width && !isString(rest.style.width) ? +rest.style.width : undefined;

  const theme = useTheme();

  const statusNameAvatar = useMemo(() => {
    const style1 = {
      width: size ? Math.floor(size * 0.25) : '1em',
      height: size ? Math.floor(size * 0.25) : '1em',
      marginLeft: size ? size / 20 : '1.2em',
      backgroundColor: `${theme.palette.background.default}`,
      border: `1px solid ${theme.palette.text.secondary}`,
    };
    const style2 = { fontSize: size ? Math.floor(size * 0.2) : '0.9em', color: ukColors.primary };

    return <Avatar style={style1}>{isDefaultName ? <SettingsIcon style={style2} /> : <Edit style={style2} />}</Avatar>;
  }, [isDefaultName, size, theme.palette.background.default, theme.palette.text.secondary]);

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      style={{ marginRight: '0.5em' }}
      badgeContent={statusNameAvatar}
    >
      <Avatar key="mainimg" src={src} {...rest}>
        {props.children}
      </Avatar>
    </Badge>
  );
};
